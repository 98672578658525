import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Switch,
} from "antd";
import { formatDateTime, openNotification } from "../../../utils";
import TicketService from "../../../services/TicketService";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";

interface TicketCardProps {
  ticketInfo: any;
  getTrigger: any;
}

const { Item } = Form;

const TicketCard: React.FC<TicketCardProps> = ({ ticketInfo, getTrigger }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [ticket, setTicket] = useState<any>();
  const [ticketComment, setTicketComment] = useState<string>();
  let role = localStorage.getItem("userRole");
  const firstLetter = ticketInfo?.user?.firstName
    ? ticketInfo.user?.firstName?.charAt(0).toUpperCase()
    : "";

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTicketComment(e.target.value);
  };

  const handleSubmit = () => {
    if (!ticketComment?.trim()) {
      // ticketComment boş veya sadece boşluklardan oluşuyorsa, burada bir hata mesajı gösterebilirsiniz.
      console.log("The comment cannot be empty.");
      return;
    }
    setLoading(true);
    sendComment();
  };

  const sendComment = () => {
    let postData = {
      ticketId: ticketInfo?.id,
      description: ticketComment,
    };

    TicketService.createComments(postData)
      .then((response: any) => {
        console.log(response.data);
        openNotification("success", "Success", "Message Sent Successfully");
        getService();
      })
      .catch((e: Error) => {
        console.log(e);
        openNotification(
          "error",
          "Failed",
          "Message could not be sent. Please Try Again Later."
        );
      })
      .finally(() => {
        setLoading(false); // Yükleme tamamlandığında
        setTicketComment(""); // Mesaj gönderildikten sonra temizle
      });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      getService();
    }
  }, [open]);

  const getService = () => {
    TicketService.get(ticketInfo?.id)
      .then((response: any) => {
        setTicket(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const update = (values: any) => {
    let status = "OPEN";

    if (values) {
      status = "CLOSED";
    }

    let postObj = {
      status: status,
    };

    TicketService.update(ticketInfo?.id, postObj)
      .then((response: any) => {
        getTrigger();
      })
      .catch((e: Error) => {});
  };

  return (
    <>
      <Card className="border-radius-md w-full mt-24">
        <Row align={"middle"} justify={"space-between"}>
          <Col xl={4} md={8} xs={12}>
            <Row align={"middle"} gutter={24}>
              <Col>
                <Avatar size={48}>{firstLetter}</Avatar>
              </Col>
              <Col>
                <p className="text fs-16 mb-0">
                  <b>ID</b>
                </p>
                <p className="text-sm fs-14 text">#{ticketInfo?.id}</p>
              </Col>
            </Row>
          </Col>
          <Col xl={4} md={8} xs={12}>
            <p className="text fs-16 mb-0">
              <b>Title</b>
            </p>
            <p className="text-sm fs-14 text">{ticketInfo?.title}</p>
          </Col>
          <Col xl={4} md={8} xs={12}>
            <p className="text-sm fs-16 m-0">
              <b>Name Surname</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              #{ticketInfo?.user.id} {ticketInfo?.user.firstName}{" "}
              {ticketInfo?.user.lastName}
            </p>
          </Col>
          <Col xl={4} md={8} xs={12}>
            <p className="text fs-16 mb-0">
              <b>Statu</b>
            </p>
            <p className="text-sm fs-14 text">
              {ticketInfo?.status === "OPEN" ? "Açık" : "Kapalı"}
            </p>
          </Col>
          <Col xl={4} md={8} xs={12}>
            <p className="text fs-16 mb-0">
              <b>Creation Date</b>
            </p>
            <p className="text-sm fs-14 text">
              {formatDateTime(ticketInfo?.createdAt.toLocaleString())}
            </p>
          </Col>
          <Col>
            <Button
              onClick={showDrawer}
              className="btn bg-cloud font-color-slate-blue btn-text border-radius-lg"
            >
              Detail
            </Button>
          </Col>
        </Row>
      </Card>
      <Drawer
        title="Destek Talebi Detay"
        placement="right"
        onClose={onClose}
        open={open}
        extra={<b>#{ticket?.id}</b>}
      >
        <h4 style={{ margin: "0px" }}>Title</h4>
        <p> {ticket?.title}</p>
        <br />
        <h4 style={{ margin: "0px" }}>Description</h4>
        <p>{ticket?.description}</p>
        <br />
        <h4 style={{ margin: "0px" }}>Registration Date</h4>
        <p>{formatDateTime(ticket?.createdAt)}</p>
        <br />
        <h4 style={{ marginTop: "0px" }}>Ticket Answer</h4>
        <TextArea
          value={ticketComment}
          size="large"
          placeholder="Write your answer"
          onChange={handleCommentChange}
        />
        <Row style={{ marginTop: "1rem" }} justify="end">
          <Col>
            <Button
              className="btn btn-hover-white bg-slate-blue font-color-cloud btn-text"
              onClick={handleSubmit}
              loading={loading}
              disabled={!ticketComment?.trim()} // Eğer ticketComment boşsa, butonu devre dışı bırak
            >
              Send
            </Button>
          </Col>
        </Row>
        {role != "CUSTOMER" ? (
          <>
            <Divider></Divider>
            <h4 style={{ margin: "0px" }}>Customer</h4>
            <p>
              <b>#{ticket?.user?.id}</b> {ticket?.user?.firstName}{" "}
              {ticket?.user?.lastName}
            </p>
            <br />
            <h4 style={{ margin: "0px" }}>Email</h4>
            <p>{ticket?.user?.email}</p>
            <Divider />
            <Switch
              checkedChildren={<b>OFF</b>}
              unCheckedChildren={<b>ON</b>}
              onChange={update}
              defaultChecked={ticketInfo?.status === "CLOSED" ? true : false}
            />
          </>
        ) : (
          <></>
        )}
        <Divider></Divider>
        <h3>Messages</h3>
        {ticket?.comments.map((comment: any, index: number) => (
          <div key={index}>
            <Row justify={"space-between"}>
              <Col>
                <h4 style={{ marginBottom: "0.3rem" }}>
                  {comment.user.firstName} {comment.user.lastName}
                </h4>
              </Col>
              <Col>
                <h5 style={{ marginBottom: "0.3rem", color: "grey" }}>
                  {formatDateTime(comment.createdAt)}
                </h5>
              </Col>
            </Row>
            <p style={{ marginTop: 0 }}>{comment.description}</p>
          </div>
        ))}
      </Drawer>
    </>
  );
};

export default TicketCard;

function setLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}
