import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./dashboard-v2.scss";
import QuickAccess from "../../components/DashboardV2/QuickAccess";
import Graph from "../../components/DashboardV2/Graph";
import ShipmentList from "../../components/DashboardV2/ShipmentList";
import StarterCard from "../../components/V2/ProfileV2/StarterCard";
import AdminStarterCard from "../../components/V2/LayoutV2/StarterCard";
import TotalProducts from "../../components/V2/AdminDashboardV2/TotalProducts";
import IncompleteFBA from "../../components/V2/AdminDashboardV2/IncompleteFBA";
import IncompleteFBM from "../../components/V2/AdminDashboardV2/IncompleteFBM";
import WaitingProducts from "../../components/V2/AdminDashboardV2/WaitingProducts";
import SummaryService from "../../services/SummaryService";
import { ISummaryResponse } from "../../types/Summary";

const DashboardV2 = () => {
  const [role, setRole] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storageLevel, setStorageLevel] = useState<number>(0);
  const [productCountLoading, setProductCountLoading] = useState(false);
  const [summary, setSummary] = useState<ISummaryResponse | null>(null);

  useEffect(() => {
    const storedRole = localStorage.getItem("userRole");
    setStorageLevel(Number(localStorage.getItem("storageLevel") || 0));
    setRole(storedRole);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
  }, []);

  const getSummary = () => {
    setProductCountLoading(true);
    SummaryService.getSummary()
      .then((response) => {
        setSummary(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setProductCountLoading(false));
  };

  useEffect(() => {
    getSummary();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
  }, []);

  return (
    <div className="dashboard-v2">
      {role === "CUSTOMER" ? (
        <>
          <Row>
            <Col span={24}>
              <StarterCard />
            </Col>
          </Row>
          <Row className="mt-24" gutter={[24, 24]}>
            <Col xl={16} lg={24} md={24} sm={24}>
              <QuickAccess />
            </Col>
            <Col xl={8} lg={24} md={24} sm={24}>
              <Graph />
            </Col>
          </Row>
          <Row className="mt-24">
            <Col span={24}>
              <ShipmentList />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <AdminStarterCard />
            </Col>
          </Row>
          <Row className="mt-24" gutter={[24, 24]}>
            <Col xl={6} md={12} sm={12} xs={24}>
              <TotalProducts loading={productCountLoading} value={summary?.productCount || 0} />
            </Col>
            <Col xl={6} md={12} sm={12} xs={24}>
              <IncompleteFBA loading={productCountLoading} value={summary?.fbaOrderCount || 0} />
            </Col>
            <Col xl={6} md={12} sm={12} xs={24}>
              <IncompleteFBM loading={productCountLoading} value={summary?.fbmOrderCount || 0} />
            </Col>
            <Col xl={6} md={12} sm={12} xs={24}>
              <WaitingProducts loading={productCountLoading} value={summary?.warehouseCount || 0} />
            </Col>
          </Row>
          <Row className="mt-24">
            <Col span={24}>
              <ShipmentList />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default DashboardV2;
