import { Card, Col, Row } from "antd";
import React from "react";

const SettingsCard = () => {
  return (
    <>
      <Card className="settings-starter-card border-radius-md">
        <Row>
          <Col>
            <p className="subtitle font-color-cloud mb-0">
              <b>Settings</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="fs-12 font-color-cloud mt-0 mb-32">
              You can access the app's settings from this page
            </p>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default SettingsCard;
