import http from "../oneamz-http-common";

const getBalance = () => {
  return http.get<any>(`/app/balance`);
};

const calcShipmentFee = (data: any) => {
  return http.post<any>(`/app/shipment/fee/calculate`, data);
};

const createOneamzShippingLabel = (data: any) => {
  return http.post<any>(`/app/shipment/fba/label/create`, data);
};

const getOneamzShippingLabel = (id: any) => {
  return http.get<any>(`/app/shipment/fba/label/${id}`);
};

const getBalanceHistory = (query: any) => {
  return http.get<any>(`/app/balance/history/list${query}`);
};

const refrestToken = (data: any) => {
  return http.post<any>(`/auth/refresh`, data);
};

// Oneamz App Get User Accounts
const getUserAccounts = () => {
  return http.get<any>(`/app/user-accounts`);
};

// Oneamz App Get Order List
const getOrderListByUnshipped = (country: string) => {
  return http.get<any>(`/app/order/unshipped?country=${country}`);
};

// Amazon FBA List
const getAmazonFbaShipmentsByUserAccountId = (
  userAccountId: string,
  status: string
) => {
  return http.get<any>(
    `/app/sp-api/fba/shipments?userAccountId=${userAccountId}&queryType=SHIPMENT&shipmentStatusList=${status}`
  );
};

// Amazon FBA shipment items
const getAmazonFbaLabelByShipmentItems = (
  userAccountId: number,
  shipmentId: string
) => {
  return http.get<any>(
    `/app/sp-api/fba/shipment/items?userAccountId=${userAccountId}&shipmentId=${shipmentId}`
  );
};

// Amazon FBA label
const getAmazonFbaLabelByShipmentId = (
  userAccountId: number,
  shipmentId: string
) => {
  return http.get<any>(
    `/app/sp-api/fba/shipment/labels?userAccountId=${userAccountId}&shipmentId=${shipmentId}&pageType=PackageLabel_Thermal&labelType=BARCODE_2D&pageSize=1&pageStartIndex=0`
  );
};

const OneamzAppService = {
  createOneamzShippingLabel,
  getOneamzShippingLabel,
  getBalance,
  getBalanceHistory,
  calcShipmentFee,
  refrestToken,
  getUserAccounts,
  getOrderListByUnshipped,
  getAmazonFbaShipmentsByUserAccountId,
  getAmazonFbaLabelByShipmentItems,
  getAmazonFbaLabelByShipmentId,
};

export default OneamzAppService;
