import http from "../http-common";
import { IWarehousesRequest, IWarehousesResponse } from "../types/Warehouse";

const getAll = () => {
  return http.get<Array<IWarehousesResponse>>(`/api/v1/users/me/warehouses/list`);
};

const getAllMe = () => {
  return http.get<Array<IWarehousesResponse>>(`/api/v1/users/me/warehouses`);
};

const update = (warehouseId: any, data: any) => {
  return http.put<any>(`/api/v1/users/me/warehouses/${warehouseId}`, data);
};

const create = (data: IWarehousesRequest) => {
  return http.post<any>(`/api/v1/users/me/warehouses`, data);
};

const WarehouseMeService = {
  getAll,
  update,
  create,
  getAllMe,
};

export default WarehouseMeService;
