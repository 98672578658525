import { Card, Col, Image, Row } from "antd";
import FBMCreateImg from "../../../assets/V2/FBAV2/fba-create-card.png";

const CreateFbmCard = () => {
  return (
    <>
      <Card className="bg-slate-blue-05 pointer border-radius-md">
        <Row align={"middle"} gutter={24}>
          <Col xxl={6} lg={12}>
            <Image preview={false} src={FBMCreateImg}></Image>
          </Col>
          <Col xxl={18} lg={12}>
            <p className="subtitle font-color-pure-white">
              Create <b>FBM</b> Shipment
            </p>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CreateFbmCard;
