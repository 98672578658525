import { Card, Col, Divider, Row } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ReactComponent as WarehouseIcon } from "../../../assets/V2/TicketsV2/Warehouse.svg";
import { ReactComponent as ClockIcon } from "../../../assets/V2/TicketsV2/Clock.svg";
import { ReactComponent as WhatsappIcon } from "../../../assets/V2/TicketsV2/WhatsappLogo.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/V2/TicketsV2/Phone.svg";
import { ReactComponent as MailIcon } from "../../../assets/V2/TicketsV2/mail.svg";

const StarterCard = () => {
  const [time, setTime] = useState("");

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const updateTime = () => {
      setTime(dayjs().tz("America/New_York").format("HH:mm:ss"));
    };

    // Update time every second
    const intervalId = setInterval(updateTime, 1000);
  }, []);

  return (
    <>
      <Card className="tickets-starter-card border-radius-md">
        <Row className="mt-16 mb-16" justify={"space-between"} align={"middle"}>
          <Col xxl={8} xl={12} lg={12} md={12}>
            <h3 className="h-3 font-color-pure-white mb-0">Solution Center</h3>
            <p className="font-color-pure-white text-small m-0">
              We are here to be with you, our valued users, at every moment!
              With our customer satisfaction-oriented service approach, we offer
              uninterrupted communication 24/7.
            </p>
          </Col>
          <Col xxl={16}>
            <Row gutter={[24, 24]} justify={"end"}>
              <Col xxl={10}>
                <Card className="bg-slate-blue-blur border-cloud-02 border h-full">
                  <Row>
                    <Col span={4}>
                      <WarehouseIcon className="wh-25" />
                    </Col>
                    <Col span={20}>
                      <p className="text font-color-cloud fs-12 m-0">
                        Our Operation Hours are between <b>09:00-18:00</b>
                        {""} US Time
                      </p>
                    </Col>
                  </Row>
                  <Divider className="bg-cloud-02 mt-16 mb-16"></Divider>
                  <Row align={"middle"}>
                    <Col span={4}>
                      <ClockIcon className="wh-25" />
                    </Col>
                    <Col span={20}>
                      <p className="text fs-12 font-color-cloud m-0">
                        The current time in the US <b>(New Jersey): {time}</b>
                      </p>
                    </Col>
                  </Row>
                  <Divider className="bg-cloud-02 mt-16 mb-16"></Divider>
                  <Row align={"middle"}>
                    <Col span={4}>
                      <MailIcon className="wh-25" />
                    </Col>
                    <Col span={20}>
                      <p className="text fs-12 font-color-cloud m-0">
                        <a
                          className="font-color-cloud"
                          href="mailto:warehouse@oneamz.com"
                        >
                          warehouse@oneamz.com
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default StarterCard;
