import React from "react";
import { notification, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface CopyClipboardProps {
  text: string | null;
}

const handleCopyClick = (text: string) => {
  notification.open({
    message: "Success",
    description: "Copied to Clipboard: " + text,
    type: "success",
  });
};

export const CopyClipboard: React.FC<CopyClipboardProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <div>
      {text}
      <CopyToClipboard text={text} onCopy={() => handleCopyClick(text)}>
        <Button
          className="border-none"
          icon={<CopyOutlined />}
          style={{ marginLeft: "5px" }}
        />
      </CopyToClipboard>
    </div>
  );
};
