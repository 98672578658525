import { Col, Row } from "antd";
import StarterCard from "../../components/V2/ProfileV2/StarterCard";
import InventoryItemList from "../../components/V2/InventoryV2/InventoryItemList";

const InventoryV2 = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard></StarterCard>
        </Col>
      </Row>
      <InventoryItemList />
    </>
  );
};

export default InventoryV2;
