import { OrderStatus } from "../enums/OrderStatus";
import { IUserResponse } from "./User";

interface ProductDetail {
  asin: string;
  title: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  imageUrl: string | null;
}

interface Item {
  id: number;
  productDetail: ProductDetail;
  quantity: number;
}

interface FbmOrderResponse {
  id: number;
  user: IUserResponse;
  status: OrderStatus;
  items: Item[];
  detailUrl: string;
  createdAt: string;
}

interface FbmOrderRequest {
  page: number;
  size: number;
  search?: string;
  statuses?: OrderStatus[];
  userId?: number;
  startDate?: string;
  endDate?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class IFbmOrderResponse implements FbmOrderResponse {
  id: number;
  user: IUserResponse;
  status: OrderStatus;
  items: Item[];
  detailUrl: string;
  createdAt: string;

  constructor(obj: FbmOrderResponse) {
    this.id = obj.id;
    this.user = new IUserResponse(obj.user);
    this.status = obj.status;
    this.items = obj.items.map((item) => ({
      id: item.id,
      productDetail: item.productDetail,
      quantity: item.quantity,
    }));
    this.detailUrl = `/fbm-order/detail/${obj.id}`;
    this.createdAt = obj.createdAt;
  }
}

export class IFbmOrderRequest implements FbmOrderRequest {
  page: number;
  size: number;
  search?: string;
  statuses?: OrderStatus[];
  userId?: number;
  startDate?: string;
  endDate?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: FbmOrderRequest) {
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search ?? "";
    this.statuses = obj.statuses ?? [];
    this.userId = obj.userId;
    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedFbmOrdersResponse {
  data: FbmOrderResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedFbmOrdersResponse implements PaginatedFbmOrdersResponse {
  data: IFbmOrderResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedFbmOrdersResponse) {
    this.data = obj.data.map((order) => new IFbmOrderResponse(order));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}

interface FbmOrderUpdateRequest {
  status: OrderStatus;
}

export class IFbmOrderUpdateRequest implements FbmOrderUpdateRequest {
  status: OrderStatus;

  constructor(obj: FbmOrderUpdateRequest) {
    this.status = obj.status;
  }
}
