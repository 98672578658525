import { Card, Col, Row } from "antd";
import { ReactComponent as FBAActive } from "../../../assets/V2/LayoutV2/fba-active.svg";
import { useNavigate } from "react-router-dom";

interface IncompleteFBAProps {
  value: any;
  loading: boolean;
}
const IncompleteFBA: React.FC<IncompleteFBAProps> = ({ value, loading }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("/fba");
  };
  return (
    <>
      <Card
        onClick={handleCardClick}
        style={{ cursor: "pointer" }}
        loading={loading}
        className="bg-slate-blue-01 border-radius-md h-full"
      >
        <Row justify={"end"}>
          <Col>
            <div className="pt-16 pb-12 pr-16 pl-16 bg-slate-blue-02 border-radius-lg">
              <FBAActive />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="h-2 mb-0 font-color-slate-blue">{value}</h2>
            <p className="m-0 text font-color-slate-blue">
              Incomplete FBA Order
            </p>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default IncompleteFBA;
