import http from "../http-common";
import { ISummaryResponse } from "../types/Summary";

const getSummary = () => {
  return http.get<ISummaryResponse>(`/api/v2/summary`);
};

const SummaryService = {
  getSummary,
};

export default SummaryService;
