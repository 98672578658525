import { useEffect, useState } from "react";
import { Row, Col, Card, Popconfirm, message, Input } from "antd";
import WarehouseService from "../services/WarehouseService";
import { IWarehousesResponse } from "../types/Warehouse";
import CreateWarehouse from "../components/Warehouse/CreateWarehouse";
import SettingsRouter from "../components/Settings/SettingsRouter";
import SettingsCard from "../components/V2/SettingsV2/SettingsCard";
import WarehouseItemCard from "../components/V2/SettingsV2/WarehouseV2/WarehouseItemCard";
import WarehouseDetail from "../components/Warehouse/WarehouseDetail";
import Table, { ColumnsType } from "antd/es/table";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

interface DataType {
  id?: number;
  countryCode: string;
  type: string;
  code: string;
  address: string;
}

const Warehouses = () => {
  const [warehouses, setWarehouses] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [filteredWarehouses, setFilteredWarehouses] = useState<any>();
  const [searchText, setSearchText] = useState("");

  const WarehouseCodes: Record<string, string> = {
    W: "Warehouse",
    FC: "Fulfillment Center",
  };

  function getWarehouseTypeName(code: string): string {
    return WarehouseCodes[code] || "Unknown Warehouse Type";
  }

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    if (searchText) {
      const filteredData = warehouses.filter((item: DataType) => {
        return (
          item.countryCode?.toLowerCase().includes(searchText.toLowerCase()) ||
          getWarehouseTypeName(item.type)
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.code?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.address?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFilteredWarehouses(filteredData);
    } else {
      setFilteredWarehouses(warehouses);
    }
  }, [searchText, warehouses]);

  const getWarehouses = () => {
    setLoading(true);
    WarehouseService.getAll()
      .then((response: any) => {
        let data: IWarehousesResponse = response.data;
        console.log(data);
        setWarehouses(data);
        setFilteredWarehouses(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const countryCodes: Record<string, string> = {
    CA: "Canada",
    MX: "Mexico",
    US: "US",
    CZ: "Czech Republic",
    FR: "France",
    DE: "Germany",
    IT: "Italy",
    PL: "Poland",
    ES: "Spain",
    GB: "UK",
    IN: "India",
    JP: "Japan",
    SG: "Singapore",
    AE: "UAE",
    AU: "Australia",
  };

  function getCountryName(code: string): string {
    return countryCodes[code] || "Unknown Country";
  }

  const confirm = (id: any) => {
    WarehouseService.remove(id)
      .then((response: any) => {
        message.success("Warehouse Successfully Deleted");
        getWarehouses();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const cancel = () => {};

  const columns: ColumnsType<DataType> = [
    {
      title: "Country",
      dataIndex: "countryCode",
      key: "countryCode",
      render: (_, row) => (
        <>
          <b>{getCountryName(row.countryCode)}</b>
        </>
      ),
    },
    {
      title: "Warehouse Type",
      dataIndex: "type",
      key: "type",
      render: (_, row) => (
        <>
          <b>{getWarehouseTypeName(row.type)}</b>
        </>
      ),
    },
    {
      title: "Warehouse Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Process",
      key: "action",
      width: 130,
      render: (_, row) => (
        <>
          <Row justify={"start"} style={{ width: 90 }}>
            <WarehouseDetail
              onHandleWarehouseList={getWarehouses}
              id={row.id}
            />
            <Popconfirm
              title="Delete Warehouse"
              description="Are you sure you want to delete the warehouse?"
              onConfirm={() => confirm(row.id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined
                style={{
                  marginLeft: "10px",
                  fontSize: "1.5em",
                  cursor: "pointer",
                }}
              />
            </Popconfirm>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <SettingsCard />
        </Col>
      </Row>
      <SettingsRouter></SettingsRouter>
      <Row align={"middle"} justify={"space-between"}>
        <Col>
          <Input
            className="amz-input"
            placeholder="Search..."
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <CreateWarehouse
            onHandleWarehouseList={getWarehouses}
          ></CreateWarehouse>
        </Col>
      </Row>
      <Table
        className="mt-24"
        scroll={{ x: "100%" }}
        loading={loading}
        columns={columns}
        dataSource={filteredWarehouses}
      />
      {/* <Card loading={loading} className='bg-cloud border-radius-md'>
                <Row justify={'end'} className='mb-24'>
                    <Col span={5}>
                        <CreateWarehouse onHandleWarehouseList={getWarehouses}></CreateWarehouse>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    {warehouses?.slice(0, 25).map((warehouse: any) => (
                        <Col span={24}>
                            <WarehouseItemCard warehouse={warehouse} getWarehouses={getWarehouses} />
                        </Col>
                    ))}
                </Row>
            </Card> */}
    </>
  );
};

export default Warehouses;
