import StarterCard from "../../components/V2/TicketsV2/StarterCard";
import { Col, Row } from "antd";
import TicketList from "../../components/V2/TicketsV2/TicketList";

const TicketsV2 = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard></StarterCard>
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <TicketList></TicketList>
        </Col>
      </Row>
    </>
  );
};

export default TicketsV2;
