import React from "react";
import { Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";

interface AmazonProductInfoProps {
  asin: string;
  title: string;
}

const AmazonProductInfo: React.FC<AmazonProductInfoProps> = ({
  asin,
  title,
}) => {
  return (
    <NavLink to={`https://www.amazon.com/dp/${asin}`} target="_blank">
      <p className="text font-color-slate-blue bg-dorian p-8 d-inline border-radius-sm">
        <b>{asin}</b>
        <Tooltip title="The Amazon Standard Identification Number (ASIN) is a unique identification number assigned to Amazon.com and its partners for product identification within the Amazon.com organization.">
          <InfoIcon className="ml-4 wh-14" />
        </Tooltip>
      </p>
      <Tooltip title={title}>
        <p className="text-small font-color-slate-blue mb-0 product-title">
          {title}
        </p>
      </Tooltip>
    </NavLink>
  );
};

export default AmazonProductInfo;
