import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import { openNotification } from "../../utils";

interface CargoLabelUploadProps {
  setLabel: (file: any) => void;
  label: any;
}

const CargoLabelUpload: React.FC<CargoLabelUploadProps> = ({
  setLabel,
  label,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (fileList.length > 0) {
      setLabel(fileList[0]);
    } else {
      setLabel(null);
    }
  }, [fileList]);

  const props: UploadProps = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification(
          "warning",
          "Warning",
          "You can upload only one file"
        );
        // If there's already a file in the list, reject the new file
        return Upload.LIST_IGNORE;
      }
      // If not, add the new file to the list
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList, // Show the initial file list
    accept: ".pdf", // Accept only PDF files
    multiple: false, // Disable multiple file selection
  };

  return (
    <>
      <Upload {...props}>
        <Button icon={<UploadOutlined />}></Button>
      </Upload>
    </>
  );
};

export default CargoLabelUpload;
