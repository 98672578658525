import http from "../http-common";
import {
  IPaginatedFbmOrdersResponse,
  IFbmOrderResponse,
  IFbmOrderRequest,
  IFbmOrderUpdateRequest,
} from "../types/FbmOrder";

const getFbmOrders = (filterCriteria: IFbmOrderRequest) => {
  return http.get<IPaginatedFbmOrdersResponse>(`/api/v2/fbm-orders${`${filterCriteria}`}`);
};

const getFbmOrderById = (id: number) => {
  return http.get<IFbmOrderResponse>(`/api/v2/fbm-orders/${id}`);
};

const updateFbmOrderStatus = (id: number, requestBody: IFbmOrderUpdateRequest) => {
  return http.put<IFbmOrderResponse>(`/api/v2/fbm-orders/${id}/status`, requestBody);
};

const deleteFbmOrderById = (id: number) => {
  return http.delete<IFbmOrderResponse>(`/api/v2/fbm-orders/${id}`);
};

const FbmOrderService = {
  getFbmOrders,
  getFbmOrderById,
  updateFbmOrderStatus,
  deleteFbmOrderById,
};

export default FbmOrderService;