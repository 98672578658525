interface ResponseUser {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface RequestUser {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export class IUserResponse implements ResponseUser {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;

  constructor(obj: ResponseUser) {
    this.id = obj.id;
    this.email = obj.email;
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.phoneNumber = obj.phoneNumber;
  }
}

export class IUserRequest implements RequestUser {
  firstName: string;
  lastName: string;
  phoneNumber: string;

  constructor(obj: RequestUser) {
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.phoneNumber = obj.phoneNumber;
  }
}
