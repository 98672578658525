import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../../assets/XCircle.svg";
import { DeleteOutlined } from "@ant-design/icons";
import ServicesService from "../../../../services/ServicesService";
import { formatMoney, openNotification } from "../../../../utils";

interface ServiceItemCardProps {
  service: any;
  getServices: () => void;
}

const { Item } = Form;

const ServiceItemCard: React.FC<ServiceItemCardProps> = ({
  service,
  getServices,
}) => {
  // const displayText = item.product.name.length > 100 ? `${item.product.name.substring(0, 100)}...` : item.product.name;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState<any>(null);
  const [form] = Form.useForm();
  const [isOptional, setIsOptional] = useState(false);
  const [isPerUnit, setPerUnit] = useState(false);

  const showDetailModal = (details: any) => {
    setIsModalVisible(true);
  };

  const cancel = () => {};

  const confirm = (id: any) => {
    ServicesService.remove(id)
      .then((response: any) => {
        message.success("Service Successfully Erased");
        getServices();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (isModalVisible) {
      getService();
    }
  }, [isModalVisible]);

  const getService = () => {
    form.setFieldsValue({
      name: service?.name,
      description: service?.description,
      isOptional: service?.isOptional,
      isPerUnit: service?.isPerUnit,
      type: service?.type,
      fee: service?.fee,
    });

    setIsOptional(service?.isOptional);
    setPerUnit(service?.isPerUnit);
  };

  const update = (values: any) => {
    let postObj = {
      name: values.name,
      description: values.description,
      isOptional: values.isOptional,
      isPerUnit: values.isPerUnit,
      type: values.type,
      fee: values.fee,
    };

    ServicesService.update(service.id, postObj)
      .then((response: any) => {
        getServices();
        setIsModalVisible(false);
        openNotification("success", "Success", "Service Successfully Updated");
      })
      .catch((e: Error) => {});
  };

  return (
    <>
      <Card className="border-radius-md w-full p-24 antd-card-body-p-0 p-8">
        <Row align={"middle"} justify={"space-between"} gutter={[0, 12]}>
          <Col xl={4} md={8} xs={24}>
            <p className="text-sm fs-16 m-0">
              <b>Service</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {service.name}
            </p>
          </Col>
          <Col xl={3} md={8} sm={12} xs={24}>
            <p className="text-sm fs-16 m-0">
              <b>Description</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {service.description}
            </p>
          </Col>
          <Col xl={3} md={8} xs={12}>
            <p className="text-sm fs-16 m-0">
              <b>Optional</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {service.isOptional === true ? "Evet" : "Hayır"}
            </p>
          </Col>
          <Col xl={3} md={8} xs={12}>
            <p className="text-sm fs-16 m-0">
              <b>Per Piece</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {service.isPerUnit === true ? "Evet" : "Hayır"}
            </p>
          </Col>
          <Col xl={3} md={8} xs={12}>
            <p className="text-sm fs-16 m-0">
              <b>Service Type</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {service.type === "ORDER" ? "Gönderi" : "Ürün"}
            </p>
          </Col>
          <Col xl={3} md={8} xs={12}>
            <p className="text-sm fs-16 m-0">
              <b>Quantity Fee</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {formatMoney(service.fee)}
            </p>
          </Col>
          <Col xl={3} md={8} xs={12}>
            <Button
              className="btn btn-hover-dark bg-slate-blue-02 font-color-slate-blue btn-text border-radius-lg"
              onClick={() => showDetailModal(service)}
            >
              Detail
            </Button>
          </Col>
          <Col xl={3} md={8} xs={12}>
            <Row justify={"start"}>
              <Popconfirm
                title="Delete Service"
                description="Are you sure you want to delete the service?"
                onConfirm={() => confirm(service.id)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{
                    marginLeft: "10px",
                    fontSize: "1.5em",
                    cursor: "pointer",
                  }}
                />
              </Popconfirm>
            </Row>
          </Col>
        </Row>
      </Card>
      <Modal
        title={<>{service.name}</>}
        visible={isModalVisible}
        className="antd-modal-secondary "
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={update} layout="vertical">
          <Item
            name="name"
            label="Service Name"
            rules={[
              {
                required: true,
                message: "Service name required",
              },
            ]}
          >
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item name="description" label="Description">
            <Input className="amz-input border-radius-8" />
          </Item>
          <Row gutter={24}>
            <Col>
              <Item name="isOptional" label="Optional">
                <Switch
                  className="switch-slate-blue"
                  checked={isOptional}
                  onChange={() => setIsOptional(!isOptional)}
                />
              </Item>
            </Col>
            <Col>
              <Item name="isPerUnit" label="Per Piece">
                <Switch
                  className="switch-slate-blue"
                  checked={isPerUnit}
                  onChange={() => setPerUnit(!isPerUnit)}
                />
              </Item>
            </Col>
          </Row>
          <Item
            name="type"
            label="Service Type"
            rules={[
              {
                required: true,
                message: "Service Type",
              },
            ]}
          >
            <Select
              className="amz-select-input border-slate-blue border-radius-8 amz-input"
              style={{ width: "100%" }}
              options={[
                { value: "ORDER", label: "Shipment" },
                { value: "ORDER_ITEM", label: "Item" },
              ]}
            />
          </Item>
          <Item
            name="fee"
            label="Fee"
            rules={[
              {
                required: true,
                message: "Fee required",
              },
            ]}
          >
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item>
            <Row gutter={24}>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                  onClick={() => setIsModalVisible(false)}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                  htmlType="submit"
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default ServiceItemCard;
