import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  InputNumber,
  Popconfirm,
  Empty,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { getWarehouseCountryList, openNotification } from "../../utils";
import ProductService from "../../services/ProductService";
import { IProductResponse } from "../../types/Product";
import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";
import { ReactComponent as CountryIcon } from "../../assets/V2/FBACreateV2/country-icon.svg";
import { ReactComponent as WarehouseIcon } from "../../assets/V2/FBACreateV2/warehouse-icon.svg";
import AmazonProductImage from "../Common/AmazonProductImage";
import AmazonProductInfo from "../Common/AmazonProductInfo";

interface FBACreateStepProps {
  addProduct: (data: any) => void;
  removeProduct: (id: any) => void;
  setQty: (id: any, qty: any) => void;
  setPurchasePrice: (id: any, purchasePrice: any) => void;
  setWarehouse: (id: any) => void;
  setOnHandleCountry: (id: any) => void;
  productList: [];
  totalQty: 0;
  totalWeight: 0;
  totalDeci: 0;
  warehouseList: [];
  warehouse: any;
  country: any;
}

const FBACreateStep1: React.FC<FBACreateStepProps> = ({
  addProduct,
  removeProduct,
  setQty,
  setPurchasePrice,
  warehouse,
  country,
  setWarehouse,
  setOnHandleCountry,
  warehouseList,
  productList,
  totalQty,
  totalWeight,
  totalDeci,
}) => {
  const [warehouseListByCountry, setWarehouseListByCountry] = useState<any>([]);
  const [countryList, setCountryList] = useState<any>([]);
  const [productLoad, setProductLoad] = useState(false);
  const [asinFind] = Form.useForm();

  const changeCountry = (country: any) => {
    setWarehouse(null);
    setOnHandleCountry(country);
    const filteredList = warehouseList.filter(
      (item: any) => item.country === country
    );
    setWarehouseListByCountry(filteredList);
  };

  const initCountry = (country: any) => {
    setOnHandleCountry(country);
    const filteredList = warehouseList.filter(
      (item: any) => item.country === country
    );
    setWarehouseListByCountry(filteredList);
  };

  useEffect(() => {
    const filteredList = warehouseList.filter(
      (item: any) => item.country === country
    );
    setWarehouseListByCountry(filteredList);
  }, [country]);

  useEffect(() => {
    initCountry(country);
    setCountryList(getWarehouseCountryList());
  }, []);

  const handleSubmit = (values: any) => {
    setProductLoad(true);
    ProductService.getbyASIN(values.asin)
      .then((response: any) => {
        let data: IProductResponse = response.data;
        addProduct(data);
        setProductLoad(false);
        asinFind.resetFields();
      })
      .catch((e: Error) => {
        setProductLoad(false);
        openNotification("warning", "Warning", "Product not found");
      });
  };

  return (
    <>
      <Card className="border-radius-md border-slate-blue-02 mt-24">
        <h3 className="h-3 font-color-slate-blue mt-0">
          Add Product with ASIN Code!
        </h3>
        <p className="text fs-14">
          In the first step to start the shipping process, add your product to
          the system with the ASIN code. In the second step, choose your special
          service and carrier option. Next, prepare your package by carefully
          previewing and labeling it accordingly. Finally, when you have
          completed all the steps, you can complete the process by confirming
          the shipment.
        </p>
        <Form form={asinFind} onFinish={handleSubmit}>
          <Row className="mt-24">
            <Col>
              <Form.Item
                className="mb-0"
                name="asin"
                rules={[
                  {
                    required: true,
                    message: "Enter ASIN code",
                  },
                ]}
              >
                <Input className="amz-input-44px" placeholder="ASIN"></Input>
              </Form.Item>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                loading={productLoad}
                className="ml-24 btn btn-hover-dark bg-oneamz-yellow font-color-slate-blue btn-text"
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row className="mt-24" justify={"center"}>
        <Col span={24}>
          <Card className="bg-cloud border-radius-md">
            {productList.map((item: any, key) => (
              <Card className="border-radius-md w-full mb-24" key={key}>
                <Row align={"top"} justify={"space-between"}>
                  <Col span={9}>
                    <Row gutter={[24, 24]}>
                      <Col>
                        <AmazonProductImage
                          asin={item.asin}
                        ></AmazonProductImage>
                      </Col>
                      <Col span={16}>
                        <AmazonProductInfo
                          asin={item.asin}
                          title={item.title}
                        ></AmazonProductInfo>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <p className="text-sm fs-12 m-0">
                          <b>DIMENSIONS</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0">
                          {item.length} x {item.width} x {item.height} in
                          <br />
                          {item.weight} lb
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <p className="text-sm fs-12 m-0">
                          <b>AMAZON PRICE</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0">
                          ${item.amount}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <p className="text-sm fs-12 m-0">
                          <b>QUANTITY</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputNumber
                          min={1}
                          onChange={(value: any) => setQty(item.id, value)}
                          defaultValue={item.qty}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={2}>
                    <Popconfirm
                      title="Should the product be delisted?"
                      onConfirm={() => removeProduct(item.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined className="cursor-pointer icon-size-20 mt-24" />
                    </Popconfirm>
                  </Col>
                </Row>
              </Card>
            ))}
            {productList.length === 0 ? (
              <Empty
                description={<h3>Add product</h3>}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ) : (
              <></>
            )}
            <Row gutter={24} justify={"end"}>
              <Col className="text font-color-slate-blue">
                Total Product: <b>{totalQty} Piece</b>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <Card className="border-radius-md border-slate-blue-02">
            <Row align={"top"}>
              <p className="text mt-0">
                <b>Select the Amazon Warehouse you want to send to</b>
              </p>
              <InfoIcon className="wh-8 ml-4" />
            </Row>
            <Row gutter={[24, 24]}>
              <Col>
                <Card className="bg-cloud">
                  <Row gutter={16} align={"middle"}>
                    <Col>
                      <div className="wh-48 icon-bg-12 bg-slate-blue-02 border-radius-lg">
                        <CountryIcon />
                      </div>
                    </Col>
                    <Col>
                      <p className="text my-0 ml-12">
                        <b>Country</b>
                      </p>
                      <Select
                        className="amz-select-input"
                        onChange={(value) => changeCountry(value)}
                        defaultValue={country}
                        value={country}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        showSearch
                        placeholder="Select Country"
                        style={{ width: 180 }}
                        options={[
                          { value: null, label: "Select" },
                          ...countryList,
                        ]}
                      ></Select>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card className="bg-cloud">
                  <Row gutter={16} align={"middle"}>
                    <Col>
                      <div className="wh-48 pl-12 pt-16 bg-slate-blue-02 border-radius-lg">
                        <WarehouseIcon />
                      </div>
                    </Col>
                    <Col>
                      <p className="text my-0 ml-12">
                        <b>Amazon Warehouse Center</b>
                      </p>
                      <Select
                        className="amz-select-input"
                        disabled={country == null}
                        value={warehouse}
                        defaultValue={warehouse}
                        onChange={(value) => setWarehouse(value)}
                        placeholder="Select"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        showSearch
                        style={{ maxWidth: 500, minWidth: 300 }}
                        options={[
                          { value: null, label: "Select" },
                          ...warehouseListByCountry,
                        ]}
                      ></Select>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FBACreateStep1;
