import http from "../http-common";
import { IPermissionResponseData } from "../types/Permission";

const getAll = () => {
  return http.get<Array<IPermissionResponseData>>("/api/v1/permissions");
};

const PermissionService = {
  getAll,
};

export default PermissionService;
