import { useEffect, useState } from "react";
import ServicesService from "../services/ServicesService";
import { IServicesResponse } from "../types/Service";
import { Row, Col, Card } from "antd";
import CreateService from "../components/Service/CreateService";
import SettingsRouter from "../components/Settings/SettingsRouter";
import SettingsCard from "../components/V2/SettingsV2/SettingsCard";
import ServiceItemCard from "../components/V2/SettingsV2/ServicesV2/ServiceItemCard";

interface DataType {
  id?: number;
  name: string;
  description: string;
  isOptional: boolean;
  isPerUnit: boolean;
  type: string;
  fee: number;
}

const Services = () => {
  const [services, setServices] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = () => {
    setLoading(true);
    ServicesService.getAll()
      .then((response: any) => {
        let data: IServicesResponse = response.data;
        console.log(data);
        setServices(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <>
      <SettingsCard />
      <SettingsRouter></SettingsRouter>
      <Row>
        <Col span={24}>
          <Card loading={loading} className="bg-cloud border-radius-md">
            <Row className="mb-24" justify={"end"}>
              <Col span={5}>
                <CreateService
                  onHandleServiceList={getServices}
                ></CreateService>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              {services?.map((service: any) => (
                <Col span={24}>
                  <ServiceItemCard
                    getServices={getServices}
                    service={service}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Services;
