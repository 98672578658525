import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Image,
  Input,
  Menu,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useEffect, useRef, useState } from "react";
import ShipmentListCard from "../../DashboardV2/ShipmentList/ShipmentListCard";
import { IResponseOrder } from "../../../types/Order";
import OrderService from "../../../services/OrderService";
import ClaimOrderService from "../../../services/ClaimOrderService";
import {
  formatDate,
  formatDateTime,
  getDateRangeDefault,
  openNotification,
} from "../../../utils";
import FbmShipmentCard from "../FBMV2/FbmShipmentCard";
import DateRange from "../../Common/DateRange";
import CreateFbmCard from "../FBMV2/CreateFbmCard";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UserService from "../../../services/UserService";
import ShipmentListCardManager from "../../DashboardV2/ShipmentList/ShipmentListCardManager";
import { ColumnProps } from "antd/es/table";
import { CopyClipboard } from "../../FBACreate/CopyClipboard";
import { EllipsisOutlined } from "@ant-design/icons";
import type { PaginationProps } from "antd";
import { debounce } from "lodash";

const StorageShipmentList = () => {
  const [dateRange, setDateRange] = useState<any[]>(getDateRangeDefault);
  const [loading, setLoading] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState([]);
  const [totalClaimOrders, setTotalClaimOrders] = useState<number>(0);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState("WAITING");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [orderType, setOrderType] = useState("STORAGE");
  const [isInWareHouse, setIsInWareHouse] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [claimOrderList, setClaimOrderList] = useState<any[]>([]);
  const [allOrders, setAllOrders] = useState<any>([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText =
    location.state?.filterText ??
    `?page=${lastCurrentPage}&size=${lastPageSize}&userId=${selectedCustomer}&orderType=${orderType}&startCreatedAt=${formatDate(
      dateRange[0]
    )}T00:00:00&endCreatedAt=${formatDate(
      dateRange[1]
    )}T23:59:59&status=${selectedStatus}&isInWareHouse=${isInWareHouse}&search=${searchText}`;
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  const [filterText, setFilterText] = useState<string>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);

  const currentRole = localStorage.getItem("userRole");
  const navigate = useNavigate();

  const statusList = [
    { value: "", label: "Select" },
    { value: "WAITING", label: "Waiting" },
    { value: "WAREHOUSE", label: "OneAmz Warehouse" },
    { value: "CANCELLED", label: "Cancelled" },
    { value: "COMPLETED", label: "Completed" },
  ];

  const statusColors: any = {
    // DRAFT: "orange",
    WAITING: "orange",
    WAREHOUSE: "purple",
    CANCELLED: "red",
    COMPLETED: "blue",
    // APPROVED: "lime",
    // SHIPPED: "green",
    "": "default", // Boş durumlar için varsayılan renk
  };

  const statusLabels = statusList.reduce((acc: any, status) => {
    acc[status.value] = status.label;
    return acc;
  }, {});

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(
      /(status=)[^&]*/,
      `$1${selectedStatus}`
    );
    newFilterText = newFilterText.replace(
      /(userId=)[^&]*/,
      `$1${selectedCustomer}`
    );
    newFilterText = newFilterText.replace(
      /(startCreatedAt=)[^&]*/,
      `$1${formatDate(dateRange[0])}T00:00:00`
    );
    newFilterText = newFilterText.replace(
      /(endCreatedAt=)[^&]*/,
      `$1${formatDate(dateRange[1])}T23:59:59`
    );
    newFilterText = newFilterText.replace(/(search=)[^&]*/, `$1${searchText}`);
    setFilterText(newFilterText);
  }, [
    currentPage,
    pageSize,
    selectedStatus,
    selectedCustomer,
    dateRange,
    searchText,
  ]);

  const debouncedSearch = useRef(
    debounce((value) => {
      setSearchText(value);
    }, 300)
  ).current;

  const handleChange = (event: any) => {
    const { value } = event.target;
    debouncedSearch(value);
  };

  const getClaimOrderList = () => {
    setLoading(true);
    ClaimOrderService.getAllWithPagination(`${filterText}`)
      .then((response: any) => {
        const data: IResponseOrder[] = response.data.data.map(
          (itemData: any) => new IResponseOrder(itemData)
        );
        setClaimOrderList(data);
        setTotalClaimOrders(response.data.totalElements);
      })
      .catch((e: Error) => {
        setClaimOrderList([]);
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";
    const sortParamRegex = /&sort=([^&]*),([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);
    let newFilterText = filterText;
    if (sortMatch) {
      newFilterText = newFilterText.replace(
        sortParamRegex,
        `&sort=${field},${direction}`
      );
    } else if (field && direction) {
      newFilterText = `${newFilterText}&sort=${field},${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getClaimOrderList();
  }, [filterText, sortField, sortOrder]);

  const userGetAll = () => {
    UserService.getAll("?roleName=CUSTOMER")
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return {
            value: item.id,
            label: `#${item.id} ${item.firstName} ${item.lastName}`,
          };
        });
        setCustomerList(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    userGetAll();
  }, []);

  useEffect(() => {
    const allOrderList = [...claimOrderList];
    // allOrderList.sort((a, b) => {
    //   if (a.orderStatus === "WAREHOUSE" && b.orderStatus !== "WAREHOUSE")
    //     return -1;
    //   if (a.orderStatus !== "WAREHOUSE" && b.orderStatus === "WAREHOUSE")
    //     return 1;
    //   if (a.orderStatus === "COMPLETED" && b.orderStatus !== "COMPLETED")
    //     return 1;
    //   if (a.orderStatus !== "COMPLETED" && b.orderStatus === "COMPLETED")
    //     return -1;
    //   return 0;
    // });
    // setAllOrders(allOrderList);
    setFilteredData(allOrderList);
  }, [claimOrderList]);

  const handleCancel = (claimOrderId: any) => {
    setLoading(true);
    // Confirm dialog before cancellation
    const confirmCancellation = window.confirm(
      "Are you sure you want to cancel this order?"
    );
    if (!confirmCancellation) {
      setLoading(false);
      return;
    }

    ClaimOrderService.cancel(claimOrderId)
      .then((response: any) => {
        openNotification("success", "Success", "Order Cancelled");
        getClaimOrderList();
      })
      .catch((error: any) => {
        console.error("Error cancelling claim order:", error);
        openNotification("error", "Error", "Error cancelling claim order");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnProps<IResponseOrder>[] = [
    {
      title: "Order Code",
      dataIndex: "orderCode",
      key: "orderCode",
      width: 150,
      render: (text: string, record: IResponseOrder) => {
        return (
          <NavLink to={record.detailUrl} style={{ color: "black" }}>
            <span>{text}</span>
          </NavLink>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      sorter: true,
      render: (text: string, record: IResponseOrder) => {
        return (
          <NavLink to={record.detailUrl} style={{ color: "black" }}>
            <span>{formatDateTime(text)}</span>
          </NavLink>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "user",
      key: "user",
      ellipsis: { showTitle: false },
      width: 150,
      sorter: true,
      render: (text: string, record: IResponseOrder) => {
        return (
          <NavLink to={record.detailUrl} style={{ color: "black" }}>
            <Tooltip
              placement="topLeft"
              title={
                record?.user.firstName +
                " " +
                record?.user.lastName +
                " #" +
                record?.user.id
              }
            >
              <p style={{ margin: 0 }}>
                {record?.user?.firstName} {record?.user?.lastName}
              </p>
              <b>#{record?.user?.id}</b>
            </Tooltip>
          </NavLink>
        );
      },
    },
    {
      title: "Items",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 220,
      render: (text: string, record: IResponseOrder) => {
        return (
          <Tooltip
            title={record?.items
              ?.map((item: any) => item?.product?.name)
              .join(", ")}
          >
            <span>
              {record?.items?.map((item: any, index: number) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <NavLink
                    to={`https://www.amazon.com/dp/${item.product.asin}`}
                    target="_blank"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => {
                      e.preventDefault(); // Tıklamayı durdur
                      window.open(
                        `https://www.amazon.com/dp/${item.product.asin}`,
                        "_blank"
                      ); // Yeni bir pencerede harici bağlantıyı aç
                    }}
                  >
                    <Image
                      style={{
                        borderRadius: "50%",
                        border: "3px solid rgba(51,62,72,0.5)",
                      }}
                      height={"40px"}
                      width={"40px"}
                      preview={false}
                      src={`https://api.oneamz.com/product-photo/${
                        item?.product?.asin
                      }?Authorization=${localStorage.getItem(
                        "externalAccessToken"
                      )}`}
                    />
                  </NavLink>
                  <CopyClipboard text={item?.product?.asin} />
                </div>
              ))}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Received/Total QTY",
      dataIndex: "qty",
      key: "qty",
      align: "center",
      width: 150,
      sorter: true,
      render: (text: string, record: IResponseOrder) => {
        const totalQty = record?.items?.reduce(
          (a: number, b: any) => a + (b?.qty || 0),
          0
        );
        const receivedQty = record?.items?.reduce(
          (a: number, b: any) => a + (b?.received || 0),
          0
        );

        return (
          <NavLink to={record.detailUrl} style={{ color: "black" }}>
            <span>
              {receivedQty} / {totalQty} PCS
            </span>
          </NavLink>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 120,
      sorter: true,
      render: (text: string, record: IResponseOrder) => {
        return (
          <NavLink to={record.detailUrl} style={{ color: "black" }}>
            <Tag
              color={statusColors[record?.orderStatus] || "inherit"}
              style={{
                fontWeight: "500",
                whiteSpace: "wrap",
                textAlign: "center",
              }}
            >
              {statusLabels[record?.orderStatus] || record?.orderStatus}
            </Tag>
          </NavLink>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      sorter: true,
      width: 100,
      render: (text: string, record: IResponseOrder) => {
        return currentRole == "CUSTOMER" ||
          (currentRole != "CUSTOMER" && record.orderStatus != "WAITING") ? (
          <Button
            type="link"
            style={{ color: "black", border: "1px solid black" }}
            onClick={() => {
              navigate(`${record.detailUrl}`);
            }}
          >
            View
          </Button>
        ) : (
          <Dropdown
            placement="topRight"
            overlay={
              <Menu>
                <Menu.Item>
                  <NavLink to={record.detailUrl}>View Details</NavLink>
                </Menu.Item>
                <Popconfirm
                  title="Are you sure you want to delete this order?"
                  onConfirm={() => handleCancel(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Menu.Item>Delete</Menu.Item>
                </Popconfirm>
              </Menu>
            }
          >
            <EllipsisOutlined
              rotate={90}
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          </Dropdown>
        );
      },
    },
  ];

  const renderCard = () => {
    return (
      <>
        <Table
          loading={loading}
          key={"id"}
          columns={columns}
          dataSource={filteredData}
          bordered
          scroll={{ x: 1000 }}
          pagination={false}
          onChange={handleTableChange}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalClaimOrders}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </>
    );
  };

  return (
    <>
      <Row gutter={24}>
        {currentRole == "CUSTOMER" && (
          <Col className="mt-24" xl={8} xs={24}>
            <NavLink to={"/fbm/new"}>
              <CreateFbmCard></CreateFbmCard>
            </NavLink>
          </Col>
        )}

        <Col className="mt-24" xl={currentRole == "CUSTOMER" ? 16 : 24} xs={24}>
          <Card className="bg-cloud border-radius-md h-full">
            <Row align={"middle"} gutter={24}>
              {currentRole != "CUSTOMER" ? (
                <Col className="mt-16" xxl={6} md={12} xs={24}>
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    className="amz-input w-full"
                    options={[{ value: "", label: "Select" }, ...customerList]}
                    showSearch
                    onChange={(customer) => setSelectedCustomer(customer)}
                    placeholder={"Customer"}
                  ></Select>{" "}
                </Col>
              ) : (
                <></>
              )}
              <Col
                className="mt-16"
                xl={currentRole == "CUSTOMER" ? 8 : 6}
                md={12}
                xs={24}
              >
                <Input
                  className="amz-input w-full"
                  placeholder="Keyword"
                  onChange={handleChange}
                />
              </Col>
              <Col
                className="mt-16"
                xl={currentRole == "CUSTOMER" ? 8 : 6}
                md={12}
                xs={24}
              >
                <Select
                  className="amz-input w-full"
                  options={statusList}
                  onChange={(status) => setSelectedStatus(status)}
                  placeholder={"Status"}
                >
                  {" "}
                </Select>
              </Col>
              <Col
                className="mt-16"
                xl={currentRole == "CUSTOMER" ? 8 : 6}
                md={12}
                xs={24}
              >
                <DateRange
                  onChangeDateRange={(dateRange) => setDateRange(dateRange)}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card className="bg-cloud border-radius-md mt-24" loading={loading}>
        <Row justify={"space-between"}>
          <Col xs={24} md={12}>
            <h3 className="h-3 m-0">Packages Acceptance List</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>{renderCard()}</Col>
        </Row>
      </Card>
    </>
  );
};

export default StorageShipmentList;
