import http from "../http-common";

const authToken = (data: any) => {
  console.log("baseurl", process.env.REACT_APP_API_BASE_URL);
  
  return http.post<any>("/api/v1/auth/token", data);
};

const verificationCallback = (data: any) => {
  return http.post("/api/v1/auth/email-verification", data);
};

const login = (data: any) => {
  return http.post("/api/v1/auth/login", data);
};

const register = (data: any) => {
  return http.post("/api/v1/auth/register", data);
};

const forgotPassword = (data: any) => {
  return http.post("/api/v1/auth/reset-password-requests", data);
};

const verifyAgain = (data: any) => {
  return http.post("/api/v1/auth/verification-send", data);
};

const resetPassword = (data: any) => {
  return http.post("/api/v1/auth/reset-passwords", data);
};
const AuthService = {
  verificationCallback,
  login,
  register,
  forgotPassword,
  resetPassword,
  verifyAgain,
  authToken,
};

export default AuthService;
