import { OrderStatus } from "../enums/OrderStatus";
import { OrderType } from "../enums/OrderType";
import { Type } from "../enums/Type";

interface Order {
  id: number;
  orderId?: number;
  status: OrderStatus;
  type?: Type;
  fbaLabel: String;
  shippingLabel: String;
  detailUrl: string;
  printUrl: string;

  //ORDER FIELDS
  claimOrderId?: number;
  orderGeneralServices?: any;
  orderItems?: any;
  note?: null;
  employeeNote?: null;

  //CLAIM ORDER FIELDS
  claimOrderGeneralServices?: any;
  claimOrderItems?: any;
  externalTransactionId?: number;

  // COMMON FIELDS
  generalServices: any[];
  items: any[];
  user: any;
  warehouse: any;

  orderCode: String;
  orderStatus: OrderStatus;
  orderType: OrderType;
  shippingProvider: string;
  shippingFee: string;
  deliveryOption: string;
  createdAt: Date;
  updatedAt: Date;
}

interface ResponseOrder {
  id: number;
  type: Type;
  orderId?: number;

  //ORDER FIELDS
  detailUrl: string;
  printUrl: string;
  claimOrderId?: number;
  orderGeneralServices?: any;
  orderItems?: any;
  orderStatus: OrderStatus;
  note?: null;
  employeeNote?: null;

  //CLAIM ORDER FIELDS
  claimOrderGeneralServices?: any;
  claimOrderItems?: any;
  status?: OrderStatus;

  // COMMON FIELDS
  orderCode: String;
  generalServices: any[];
  items: any[];
  user: any;
  warehouse: any;

  orderType: OrderType;
  shippingProvider: string;
  shippingFee: string;
  deliveryOption: string;
  createdAt: Date;
  updatedAt: Date;
}

export class IResponseOrder implements ResponseOrder {
  id: number;
  orderId?: number;
  orderStatus: OrderStatus;
  type: Type;
  detailUrl: string;
  printUrl: string;
  externalTransactionId?: number;
  fbaLabel?: String;
  shippingLabel?: String;
  orderType: OrderType;
  generalServices: any[];
  items: any[];
  shippingProvider: string;
  shippingFee: string;
  deliveryOption: string;
  createdAt: Date;
  updatedAt: Date;
  user: any;
  orderCode: String;
  note?: null;
  employeeNote?: null;
  warehouse: any;

  constructor(obj: Order) {
    // ORDER & CLAIM ORDER FIELD DIFFERENTS TO COMMON FIELDS
    this.id = obj?.claimOrderId ? obj?.claimOrderId : obj?.id;
    this.orderId = obj?.claimOrderId && obj?.id;
    this.type = obj?.claimOrderId ? Type.ORDER : Type.CLAIM_ORDER;
    this.generalServices = obj?.claimOrderId
      ? obj.orderGeneralServices
      : obj.claimOrderGeneralServices;
    this.items = obj?.claimOrderId ? obj?.orderItems : obj?.claimOrderItems;
    this.orderStatus = obj?.claimOrderId ? obj.orderStatus : obj.status;
    this.fbaLabel = obj?.fbaLabel;
    this.shippingLabel = obj?.shippingLabel;
    this.detailUrl = obj?.claimOrderId
      ? `/order/detail/${obj.claimOrderId}`
      : `/claim/detail/${obj.id}`;

    // CLAIM ORDER
    if (!obj?.claimOrderId) {
      this.externalTransactionId = obj?.externalTransactionId;
    }

    // ORDER
    if (!obj?.claimOrderId) {
      this.note = obj?.note;
      this.employeeNote = obj?.employeeNote;
    }
    // COMMON FIELDS
    this.orderType = obj?.orderType;
    // console.log("this", this.orderType);
    // console.log("obj", obj?.orderType);

    switch (this.orderType) {
      case "FBA_SHIPMENT":
        this.orderCode = `ONESHIP-${obj?.user?.id}-FBA-${
          this.type === "ORDER" ? obj?.claimOrderId : obj?.id
        }`;
        this.printUrl = obj?.claimOrderId ? `` : `/fba/print/${obj.id}`;
        break;
      case "FBM_SHIPMENT":
        this.orderCode = `ONESHIP-${obj?.user?.id}-FBM-${
          this.type === "ORDER" ? obj?.claimOrderId : obj?.id
        }`;
        this.printUrl = obj?.claimOrderId ? `` : `/fbm/print/${obj.id}`;
        break;
      case "STORAGE":
        this.orderCode = `ONESHIP-${obj?.user?.id}-STORAGE-${
          this.type === "ORDER" ? obj?.claimOrderId : obj?.id
        }`;
        this.printUrl = obj?.claimOrderId ? `` : `/storage/print/${obj.id}`;
        break;
      default:
        this.orderCode = "UNKNOWN";
        this.printUrl = "";
    }

    this.warehouse = obj?.warehouse;
    this.shippingProvider = obj?.shippingProvider;
    this.shippingFee = obj?.shippingFee;
    this.deliveryOption = obj?.deliveryOption;
    this.user = obj?.user;
    this.createdAt = new Date(obj?.createdAt);
    this.updatedAt = new Date(obj?.updatedAt);
  }
}
