import { Card, Col, Row } from "antd";
import { ReactComponent as WarehouseIcon } from "../../../assets/V2/OrderDetailV2/warehouse-icon.svg";
import { ReactComponent as HouseCheckIcon } from "../../../assets/V2/OrderDetailV2/house-check.svg";
import { IWarehousesResponse } from "../../../types/Warehouse";

interface ShipmentDetailProps {
  warehouse: IWarehousesResponse;
}
const ShipmentDetail: React.FC<ShipmentDetailProps> = ({ warehouse }) => {
  return (
    <>
      <Row>
        <Col>
          <h3 className="h-3">Shipment Details</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card className="bg-slate-blue border-radius-md">
            <Row>
              <Col span={4}>
                <WarehouseIcon style={{ height: "48px", width: "48px" }} />
              </Col>
              <Col span={20}>
                <p className="text font-color-pure-white">
                  <b>Sent to Amazon Warehouse</b>
                </p>
                <p className="pretitle font-color-pure-white">
                  {warehouse?.countryCode}
                </p>
                <p className="pretitle font-color-pure-white">
                  {warehouse?.address}
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={24} className="mt-24">
        <Col span={24}>
          <Card className="bg-cloud border-radius-md">
            <Row>
              <Col span={4}>
                <HouseCheckIcon className="wh-48" />
              </Col>
              <Col span={20}>
                <p className="text font-color-onyx">
                  <b>OneAmz Warehouse Address</b>
                </p>
                <p className="text font-color-onyx m-0">356 GETTY AVE</p>
                <p className="text font-color-onyx m-0">
                  BLD-5A # ONEAMZ-0413464
                </p>
                <p className="text font-color-onyx m-0">
                  CLIFTON, NJ 07011-2118
                </p>
                <p className="text font-color-onyx m-0">United States</p>
                <p className="text-small font-color-onyx">
                  <b>917 306 6033</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ShipmentDetail;
