import { Card, Col, Row } from "antd";

const CustomersStarterCard = () => {
  return (
    <>
      <Card className="customers-starter-card border-radius-md">
        <Row>
          <Col>
            <p className="subtitle font-color-cloud mb-0">
              <b>Customers</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="fs-12 font-color-cloud mt-0 mb-32">
              You can access customer information using the app from this page
            </p>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CustomersStarterCard;
