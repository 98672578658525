import React from "react";
import { Avatar, Tooltip } from "antd";
import { NavLink } from "react-router-dom";

interface AmazonProductImageAvatarProps {
  orderItem: any;
}

const AmazonProductImageAvatar: React.FC<AmazonProductImageAvatarProps> = ({
  orderItem,
}) => {
  return (
    <Tooltip
      title={`${orderItem.productDetail.asin} ${orderItem.productDetail.title}`}
      placement="top"
    >
      <NavLink
        to={`https://www.amazon.com/dp/${orderItem.productDetail.asin}`}
        target="_blank"
      >
        {/* <Badge overflowCount={9999999} color="#333E48" count={orderItem.qty}> */}
        <Avatar
          size={64}
          className="avatar-border"
          src={`https://api.oneamz.com/product-photo/${
            orderItem.productDetail.asin
          }?Authorization=${localStorage.getItem("externalAccessToken")}`}
        />
        {/* </Badge> */}
      </NavLink>
    </Tooltip>
  );
};

export default AmazonProductImageAvatar;
