import http from "../http-common";

const uploadFbaLabel = (id: any, file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      maxBodyLength: Infinity,
      "Content-Type": "multipart/form-data",
    },
  };

  return http.post(`/api/v1/claim-orders/${id}/fba/upload`, formData, config);
};

const uploadFbmLabel = (id: any, file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      maxBodyLength: Infinity,
      "Content-Type": "multipart/form-data",
    },
  };

  return http.post(`/api/v1/claim-orders/${id}/fbm/upload`, formData, config);
};

const uploadOrderFbaLabel = (id: any, file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      maxBodyLength: Infinity,
      "Content-Type": "multipart/form-data",
    },
  };

  return http.post(`/api/v1/orders/${id}/fba/upload`, formData, config);
};

const uploadCargoLabel = (id: any, file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      maxBodyLength: Infinity,
      "Content-Type": "multipart/form-data",
    },
  };

  return http.post(
    `/api/v1/claim-orders/${id}/shipping-label/upload`,
    formData,
    config
  );
};

const uploadOrderCargoLabel = (id: any, file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      maxBodyLength: Infinity,
      "Content-Type": "multipart/form-data",
    },
  };

  return http.post(`/api/v1/orders/${id}/shipping-label/upload`, formData, config);
};

const uploadFnskuLabel = (id: any, file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      maxBodyLength: Infinity,
      "Content-Type": "multipart/form-data",
    },
  };

  return http.post(`/api/v1/claim-order-items/${id}/fnsku/upload`, formData, config);
};

const uploadOrderItemFNSKULabel = (id: any, file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      maxBodyLength: Infinity,
      "Content-Type": "multipart/form-data",
    },
  };

  return http.post(`/api/v1/order-items/${id}/fnsku/upload`, formData, config);
};

const FileUploadService = {
  uploadFbaLabel,
  uploadFbmLabel,
  uploadFnskuLabel,
  uploadCargoLabel,
  uploadOrderItemFNSKULabel,
  uploadOrderFbaLabel,
  uploadOrderCargoLabel,
};

export default FileUploadService;
