import { Button, Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import ServicesService from "../../services/ServicesService";
import closeIcon from "../../assets/XCircle.svg";

interface ServiceDetailProps {
  onHandleServiceList: () => void;
}

const { Item } = Form;

const CreateService: React.FC<ServiceDetailProps> = ({
  onHandleServiceList,
}) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [isModalVisible]);

  const update = (values: any) => {
    let postObj = {
      name: values.name,
      description: values.description,
      isOptional: values.isOptional,
      isPerUnit: values.isPerUnit,
      type: values.type,
      fee: values.fee,
    };

    console.log(postObj);

    ServicesService.create(postObj)
      .then((response: any) => {
        onHandleServiceList();
        setIsModalVisible(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <>
      <Row justify={"end"}>
        <Button
          className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-8"
          onClick={() => setIsModalVisible(true)}
        >
          Add Service
        </Button>
      </Row>
      <Modal
        title={<>Add Service</>}
        visible={isModalVisible}
        className="antd-modal-secondary "
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={update} layout="vertical">
          <Item
            name="name"
            label="Service Name"
            rules={[
              {
                required: true,
                message: "Service name required",
              },
            ]}
          >
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item name="description" label="Description">
            <Input className="amz-input border-radius-8" />
          </Item>
          <Row gutter={24}>
            <Col>
              <Item name="isOptional" label="Optional">
                <Switch className="switch-slate-blue" />
              </Item>
            </Col>
            <Col>
              <Item name="isPerUnit" label="Per Piece">
                <Switch className="switch-slate-blue" />
              </Item>
            </Col>
          </Row>
          <Item
            name="type"
            label="Service Type"
            rules={[
              {
                required: true,
                message: "Service Type",
              },
            ]}
          >
            <Select
              className="amz-select-input border-slate-blue border-radius-8 amz-input"
              style={{ width: "100%" }}
              options={[
                { value: "ORDER", label: "Shipment" },
                { value: "ORDER_ITEM", label: "Product" },
              ]}
            />
          </Item>
          <Item
            name="fee"
            label="Fee"
            rules={[
              {
                required: true,
                message: "Fee required",
              },
            ]}
          >
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item>
            <Row gutter={24}>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                  onClick={() => setIsModalVisible(false)}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                  htmlType="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateService;
