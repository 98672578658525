import { Card, Col, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "./packagelist.scss";
import FBALabelUpload from "./FBALabelUpload";

class Product {
  key: number;
  color: string;
  asin: string;
  name: string;
  width: number;
  height: number;
  depth: number;
  weight: number;
  quantity: number;

  constructor(
    key: number,
    color: string,
    asin: string,
    name: string,
    width: number,
    height: number,
    depth: number,
    weight: number,
    quantity: number
  ) {
    this.key = key;
    this.color = color;
    this.asin = asin;
    this.name = name;
    this.width = width;
    this.height = height;
    this.depth = depth;
    this.weight = weight;
    this.quantity = quantity;
  }
}

class Box {
  products: Product[] = [];
  maxWidth: number = 24; // inches
  maxHeight: number = 24; // inches
  maxDepth: number = 24; // inches
  maxWeight: number = 45; // lbs

  getTotalWeight(): string {
    const totalWeight = this.products.reduce(
      (sum, p) => sum + p.weight * p.quantity,
      0
    );
    return totalWeight.toFixed(2);
  }

  getDesi(): string {
    const divisionNumber = 5000;
    const totalDesi = this.products.reduce((sum, p) => {
      const volumeInInches = p.width * p.height * p.depth;
      const volumeInCm = volumeInInches * 16.387;
      return sum + (volumeInCm / divisionNumber) * p.quantity;
    }, 0);
    return totalDesi.toFixed(2);
  }

  canAddProduct(product: Product): boolean {
    let currentWeight = this.products.reduce(
      (sum, p) => sum + p.weight * p.quantity,
      0
    );
    if (currentWeight + product.weight * product.quantity > this.maxWeight) {
      return false;
    }

    let usedVolume = this.products.reduce(
      (sum, p) => sum + p.width * p.height * p.depth * p.quantity,
      0
    );
    let newProductVolume =
      product.width * product.height * product.depth * product.quantity;
    let maxVolume = this.maxWidth * this.maxHeight * this.maxDepth;

    return usedVolume + newProductVolume <= maxVolume;
  }

  addProduct(product: Product): boolean {
    if (this.canAddProduct(product)) {
      this.products.push(product);
      return true;
    }
    return false;
  }

  hasSameASIN(product: Product): boolean {
    if (this.products.length === 0) return true;
    return this.products[0].asin === product.asin;
  }
}

interface PackingSolutionProps {
  orderItemList: any[];
  totalWeight: number;
  fbaLabel: any;
  orderServices: any[];
  setFBALabel: (item: any) => void;
  changeBoxCount: (item: any) => void;
}

const PackageList: React.FC<PackingSolutionProps> = ({
  orderItemList,
  totalWeight,
  orderServices,
  changeBoxCount,
  setFBALabel,
  fbaLabel,
}) => {
  const [boxes, setBoxes] = useState<Box[]>([]);
  const [totalOrderServiceFree, setTotalOrderServiceFee] = useState(0);

  useEffect(() => {
    changeBoxCount(boxes.length);
  }, [boxes]);

  useEffect(() => {
    let orderServiceFree = 0;
    orderServices.forEach((service: any) => {
      if (service.qty > 0) {
        orderServiceFree += service.qty * service.fee;
      }
    });
    setTotalOrderServiceFee(orderServiceFree);
  }, [orderServices]);

  const handlePacking = (products: Product[]) => {
    let tempBoxes: Box[] = [];
    let groupedProducts = products.reduce<{ [key: string]: Product[] }>(
      (acc, product) => {
        acc[product.asin] = acc[product.asin] || [];
        acc[product.asin].push(product);
        return acc;
      },
      {}
    );

    Object.values(groupedProducts).forEach((group) => {
      group.forEach((product) => {
        for (let i = 0; i < product.quantity; i++) {
          let added = false;
          for (let box of tempBoxes) {
            if (
              box.hasSameASIN(product) &&
              box.addProduct(
                new Product(
                  product.key,
                  product.color,
                  product.asin,
                  product.name,
                  product.width,
                  product.height,
                  product.depth,
                  product.weight,
                  1
                )
              )
            ) {
              added = true;
              break;
            }
          }
          if (!added) {
            let newBox = new Box();
            newBox.addProduct(
              new Product(
                product.key,
                product.color,
                product.asin,
                product.name,
                product.width,
                product.height,
                product.depth,
                product.weight,
                1
              )
            );
            tempBoxes.push(newBox);
          }
        }
      });
    });

    setBoxes(tempBoxes);
  };

  useEffect(() => {
    let productList: Product[] = [];
    orderItemList.forEach((orderItem: any, index: number) => {
      productList.push(
        new Product(
          index + 1,
          orderItem.color,
          orderItem.asin,
          orderItem.name,
          orderItem.width,
          orderItem.height,
          orderItem.length,
          orderItem.weight,
          orderItem.qty
        )
      );
    });
    handlePacking(productList);
  }, [orderItemList]);

  return (
    <Row gutter={[24, 24]}>
      <Col xl={18} lg={24} md={24}>
        <Card>
          <Row>
            {boxes?.map(
              (box, index) =>
                index < 10 && (
                  <Col xl={12} key={index}>
                    <Row gutter={[16, 16]} justify={"start"} className="mt-24">
                      <Col>
                        <div
                          className="package-box"
                          style={{ minHeight: "100%" }}
                        >
                          {box.products.map((product, pIndex) => (
                            <Tooltip
                              key={pIndex}
                              placement="top"
                              title={
                                <>
                                  <b>Product:</b> {product.asin} <br />
                                  {product.width} x {product.height} x{" "}
                                  {product.depth}, Weight: {product.weight} lb
                                </>
                              }
                            >
                              <div className="amz-product-box"></div>
                            </Tooltip>
                          ))}
                        </div>
                      </Col>
                      <Col span={8}>
                        <h3 className="text m-0">
                          <b>Package {index + 1}</b>
                        </h3>
                        <p className="text-small font-color-slate-blue-06 mt-16 mb-0">
                          <b>{box.products[0]?.asin} </b>
                        </p>
                        <Tooltip title={box.products[0]?.name}>
                          <p className="text-small m-0 font-color-slate-blue-06 product-title fs-12">
                            <b>{box.products[0]?.name} </b>
                          </p>
                        </Tooltip>
                        <h4 className="text mt-8 mb-0">
                          <b>{box.getTotalWeight()} LB</b>
                        </h4>
                        <h4 className="text m-0 fs-12 ">
                          <b>24X24X24"</b>
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                )
            )}
            {boxes.length > 10 && (
              <h3 className="mt-24">and {boxes.length - 10} box</h3>
            )}
          </Row>
        </Card>
      </Col>
      <Col xl={6} lg={24} md={24}>
        <FBALabelUpload
          type="fba"
          fbaLabel={fbaLabel}
          setLabel={(file) => setFBALabel(file)}
        ></FBALabelUpload>
      </Col>
    </Row>
  );
};

export default PackageList;
