import http from "../http-common";
import { IAisleRequest, IAisleResponse } from "../types/Aisle";

const getAll = () => {
  return http.get<Array<IAisleResponse>>(`/api/v1/aisles`);
};

const get = (aisleId: number) => {
  return http.get<IAisleResponse>(`/api/v1/aisles/${aisleId}`);
};

const update = (aisleId: any, data: IAisleRequest) => {
  return http.put<any>(`/api/v1/aisles/${aisleId}`, data);
};

const create = (data: IAisleRequest) => {
  return http.post<any>(`/api/v1/aisles`, data);
};

const remove = (aisleId: any) => {
  return http.delete<any>(`/api/v1/aisles/${aisleId}`);
};

const AisleService = {
  getAll,
  get,
  update,
  create,
  remove,
};

export default AisleService;
