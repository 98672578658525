import http from "../http-common";

const getMe = () => {
  return http.get<any>(`/api/v1/users/me`);
};

const getWallet = () => {
  return http.get<any>(`/api/v1/users/me/wallets`);
};

const addCredit = (userId: any, data: any) => {
  return http.post<any>(`/api/v1/users/${userId}/wallets/top-up`, data);
};

const getAll = (filter = "") => {
  return http.get<any>(`/api/v1/users${filter}`);
};

const getAllCount = (filter = "") => {
  return http.get<any>(`/api/v1/users/count${filter}`);
};

const updateMe = (data: any) => {
  return http.patch<any>(`/api/v1/users/me`, data);
};

const update = (userId: any, data: any) => {
  return http.patch<any>(`/api/v1/users/${userId}`, data);
};

const getStats = () => {
  return http.get<any>(`/api/v1/users/me/stats/transactions-summary`);
};

const UserService = {
  getMe,
  getStats,
  getAllCount,
  getWallet,
  addCredit,
  getAll,
  updateMe,
  update,
};

export default UserService;
