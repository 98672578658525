import React, { useEffect, useState } from "react";
import { Button, Card, Col, Popconfirm, Radio, Row, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { openNotification } from "../../../utils";
import { IFbaOrderResponse } from "../../../types/FbaOrder";
import { IFbmOrderResponse } from "../../../types/FbmOrder";
import ClaimOrderService from "../../../services/ClaimOrderService";
import FbaOrderService from "../../../services/FbaOrderService";
import FbmOrderService from "../../../services/FbmOrderService";

interface OrderDetailStatusProps {
  order: IFbaOrderResponse | IFbmOrderResponse;
}

const OrderDetailStatus: React.FC<OrderDetailStatusProps> = ({ order }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentRole = localStorage.getItem("userRole");

  const statusOrder = location.pathname.includes("/storage-order/detail")
    ? ["WAITING", "IN_WAREHOUSE", "COMPLETED"]
    : ["WAITING", "IN_WAREHOUSE", "COMPLETED", "SHIPPED"];

  const statusTranslations: any = {
    WAITING: "WAITING",
    IN_WAREHOUSE: "IN WAREHOUSE",
    COMPLETED: "COMPLETED",
    SHIPPED: "SHIPPED",
  };

  const optionStatus: any[] = location.pathname.includes(
    "/storage-order/detail"
  )
    ? [
        { value: "WAITING", label: "WAITING" },
        { value: "IN_WAREHOUSE", label: "IN WAREHOUSE" },
        { value: "COMPLETED", label: "COMPLETED" },
      ]
    : [
        { value: "WAITING", label: "WAITING" },
        { value: "IN_WAREHOUSE", label: "IN WAREHOUSE" },
        { value: "COMPLETED", label: "COMPLETED" },
        { value: "SHIPPED", label: "SHIPPED" },
      ];

  const radioButtonChecker = (status: string) => {
    return value.includes(status);
  };

  useEffect(() => {
    const statusIndex = statusOrder.indexOf(order?.status);
    if (statusIndex !== -1) {
      setValue(statusOrder.slice(0, statusIndex + 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.status]);

  const confirm = () => {
    ClaimOrderService.cancel(order?.id).then((response: any) => {
      openNotification("success", "Success", "Shipment canceled");
      if (location.pathname.includes("/fba-order/detail")) {
        navigate("/fba");
      } else if (location.pathname.includes("/fbm-order/detail")) {
        navigate("/fbm");
      } else if (location.pathname.includes("/storage-order/detail")) {
        navigate("/storage");
      }
    });
  };

  const changeStatus = (value: any) => {
    let postObj = {
      status: value,
    };
    setLoading(true);
    if(location.pathname.includes("/fba-order/detail")){
      FbaOrderService.updateFbaOrderStatus(order?.id, postObj)
      .then((response: any) => {
        openNotification("success", "Success", "Changed shipment status");
        setValue((prevValue) => {
          const newValueIndex = statusOrder.indexOf(value);
          if (newValueIndex !== -1) {
            const newSelectedStatuses = statusOrder.slice(0, newValueIndex + 1);
            return newSelectedStatuses;
          }
          return prevValue;
        });
      })
      .catch((e: Error) => {
        console.log(e);
        openNotification("error", "Error", "Failed to change shipment status");
      })
      .finally(() => setLoading(false));
    } else if(location.pathname.includes("/fbm-order/detail")){
      FbmOrderService.updateFbmOrderStatus(order?.id, postObj)
      .then((response: any) => {
        openNotification("success", "Success", "Changed shipment status");
        setValue((prevValue) => {
          const newValueIndex = statusOrder.indexOf(value);
          if (newValueIndex !== -1) {
            const newSelectedStatuses = statusOrder.slice(0, newValueIndex + 1);
            return newSelectedStatuses;
          }
          return prevValue;
        });
      })
      .catch((e: Error) => {
        console.log(e);
        openNotification("error", "Error", "Failed to change shipment status");
      })
      .finally(() => setLoading(false));
    }
    // OrderService.statusUpdate(order.orderId, postObj)
    //   .then((response: any) => {
    //     openNotification("success", "Success", "Changed shipment status");
    //     setValue((prevValue) => {
    //       const newValueIndex = statusOrder.indexOf(value);
    //       if (newValueIndex !== -1) {
    //         const newSelectedStatuses = statusOrder.slice(0, newValueIndex + 1);
    //         return newSelectedStatuses;
    //       }
    //       return prevValue;
    //     });
    //   })
    //   .catch((e: Error) => {});
  };

  return (
    <>
      <Card className="border-radius-md bg-slate-blue">
        <Row justify={"space-between"} align={"middle"}>
          {statusOrder.map((status, index) => (
            <Col key={index}>
              <Radio
                className={`cursor-default amz-radio-oneamz-yellow table-title ${
                  value.includes(status)
                    ? "font-color-pure-white"
                    : "font-color-amz-gray"
                }`}
                checked={radioButtonChecker(status)}
                onChange={() => changeStatus(status)}
                disabled={loading}
              >
                {statusTranslations[status]}
              </Radio>
            </Col>
          ))}
        </Row>
        <Row justify={"space-between"} align={"middle"}>
          <Col span={12}>
            {!location.pathname.includes("/storage-order/detail") &&
              currentRole === "ADMIN" && (
                <Select
                  className="amz-input mt-12"
                  placeholder="Change Status"
                  options={optionStatus}
                  size="large"
                  style={{ width: "50%", minWidth: "185px" }}
                  defaultValue={order?.status}
                  onChange={(value: any) => {
                    changeStatus(value);
                  }}
                  disabled={loading}
                />
              )}
          </Col>
          <Col span={12}>
            <Row justify={"end"}>
              {order?.status !== "COMPLETED" &&
                order?.status !== "SHIPPED" &&
                order?.status !== "CANCELLED" && (
                  <Popconfirm
                    title="Shipment Cancel"
                    description="Do you want to cancel the shipment?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue mt-12">
                      Cancel
                    </Button>
                  </Popconfirm>
                )}
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default OrderDetailStatus;
