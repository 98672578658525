import http from "../http-common";

const getAll = (filter = "") => {
  let role = localStorage.getItem("userRole");

  if (role === "CUSTOMER") {
    return http.get<Array<any>>(`/api/v1/users/me/inventories${filter}`);
  }

  return http.get<Array<any>>(`/api/v1/inventories${filter}`);
};

const getAllWithPagination = (filterCriteria: string = ''  ) => {
  let role = localStorage.getItem("userRole");
  if (role === "CUSTOMER") {
    return http.get<Array<any>>(`/api/v1/users/me/inventories${filterCriteria}`);
  }
  return http.get<Array<any>>(`/api/v1/inventories${filterCriteria}`);
};


const inventorySearch = (input: any) => {
  return http.get(`/api/v1/users/me/inventories?search=${input}&size=999`);
};

const getTotalStorageVolume = () => {
  return http.get(`/api/v1/users/me/inventories/total-storage-volume`);
};

const getAllCount = (filter = "") => {
  let role = localStorage.getItem("userRole");
  if (role === "CUSTOMER") {
    return http.get<Array<any>>(`/api/v1/users/me/orders/count${filter}`);
  }
  return http.get<Array<any>>(`/api/v1/orders/count${filter}`);
};

const InventoryService = {
  getAll,
  getAllWithPagination,
  inventorySearch,
  getAllCount,
  getTotalStorageVolume
};

export default InventoryService;
