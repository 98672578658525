import React from "react";
import { IResponseOrder } from "../../../types/Order";
import { useLocation, useNavigate } from "react-router-dom";
import { openNotification } from "../../../utils";
import OrderService from "../../../services/OrderService";
import { Button, Card, Col, Divider, Popconfirm, Row } from "antd";
import { ReactComponent as UserIcon } from "../../../assets/icons/User.svg";
import FBAShipmentItemCardManager from "./FBAShipmentItemCardManager";
import { ReactComponent as WarehouseIcon } from "../../../assets/V2/OrderDetailV2/warehouse-icon.svg";
import { ReactComponent as HouseCheckIcon } from "../../../assets/V2/OrderDetailV2/house-check.svg";
import OrderDetailPackageInfo from "../../../components/V2/OrderDetailV2/OrderDetailPackageInfo";
import FBAShipmentCostDetail from "./FBAShipmentCostDetail";
import OrderDetailLabels from "../../../components/V2/OrderDetailV2/OrderDetailLabels";
import { IFbaOrderResponse } from "../../../types/FbaOrder";
import { IFbmOrderResponse } from "../../../types/FbmOrder";

interface OrderDetailCardProps {
  order: IFbaOrderResponse | IFbmOrderResponse;
  setQty: (id: any, qty: any) => void;
}

const FBAShipmentDetailManager: React.FC<OrderDetailCardProps> = ({
  order,
  setQty,
}) => {
  const location = useLocation();
  const readOnly = !location.pathname.includes("/storage-order/detail") ? true : false;

  const navigate = useNavigate();

  const confirmOrder = () => {
    let orderItems: any = [];
    let errorCount = 0;

    // order.items.map((orderItem: any) => {
    //   if (orderItem.approvalQty > 0) {
    //     let itemServices: any[] = [];

    //     orderItem.services.map((servicesItem: any) => {
    //       console.log("orderItem", orderItem);

    //       let serviceItemObj = {
    //         generalServiceId: servicesItem.generalService.id,
    //         qty: servicesItem?.generalService?.isPerUnit
    //           ? orderItem.approvalQty
    //           : 1,
    //       };

    //       itemServices.push(serviceItemObj);
    //     });

    //     orderItem.binId = 1;

    //     let itemObj = {
    //       claimOrderItemId: orderItem.id,
    //       qty: orderItem.approvalQty,
    //       binId: orderItem.binId,
    //       claimOrderItemGeneralServices: itemServices,
    //     };
    //     orderItems.push(itemObj);
    //   }
    // });

    if (errorCount > 0) {
      openNotification(
        "error",
        "Error",
        "Select the shelf where the product will be stored"
      );
      return false;
    }

    if (orderItems.length === 0) {
      openNotification("error", "Error", "Product quantities cannot be zero");
      return false;
    }

    let orderGeneralServices: any = [];

    // order?.generalServices.map((generalServiceItem: any) => {
    //   let orderGeneralService = {
    //     generalServiceId: generalServiceItem.generalService.id,
    //     qty: generalServiceItem.qty,
    //   };

    //   orderGeneralServices.push(orderGeneralService);
    // });

    let postObj = {
      claimOrderId: order.id,
      orderItems: orderItems,
      employeeNote: "",
      oneamzToken: localStorage.getItem("externalAccessToken"),
      orderGeneralServices: orderGeneralServices,
    };

    OrderService.create(postObj)
      .then((response: any) => {
        openNotification("success", "Success", "Shipment confirmed");
        setTimeout(() => {
          window.location.replace(`/order/detail/${order.id}`);
        }, 1000);
      })
      .catch((e: Error) => {
        openNotification("warning", "Error", e.message);
      });
  };

  return (
    <Row justify={"space-between"}>
      <Col xl={8} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">User Information</h3>
          </Col>
        </Row>
        <Card className="bg-slate-blue border-radius-md">
          <Row>
            <Col span={4}>
              <UserIcon />
            </Col>
            <Col span={20}>
              <h2 className="font-color-pure-white mt-0 mb-0">
                <b>
                  {order?.user?.firstName} {order?.user?.lastName}
                </b>
              </h2>
              <h2 className="font-color-pure-white mt-0">
                <b>#{order?.user?.id} </b>
              </h2>
              <p className="text font-color-pure-white mt-24 mb-0">
                {order?.user?.phoneNumber}
              </p>
              <p className="text font-color-pure-white mt-0 mb-0">
                {order?.user?.email}
              </p>
            </Col>
          </Row>
        </Card>

        <Row>
          <Col>
            <h3 className="h-3">Shipment Details</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="bg-slate-blue border-radius-md">
              <Row>
                <Col span={4}>
                  <WarehouseIcon style={{ height: "48px", width: "48px" }} />
                </Col>
                <Col span={20}>
                  <p className="text font-color-pure-white">
                    <b>Sent to Amazon Warehouse</b>
                  </p>
                  <p className="pretitle font-color-pure-white">
                    {/* {order?.warehouse?.countryCode} */}
                    order?.warehouse?.countryCode
                  </p>
                  <p className="pretitle font-color-pure-white">
                    {/* {order?.warehouse?.address} */}
                    order?.warehouse?.address
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={24} className="mt-24">
          <Col span={24}>
            <Card className="bg-cloud border-radius-md">
              <Row>
                <Col span={4}>
                  <HouseCheckIcon className="wh-48" />
                </Col>
                <Col span={20}>
                  <p className="text font-color-onyx">
                    <b>OneAmz Warehouse Address</b>
                  </p>
                  <p className="text font-color-onyx m-0">356 GETTY AVE</p>
                  <p className="text font-color-onyx m-0">
                    {/* BLD-5A #{order?.orderCode} */}
                    BLD-5A #order?.orderCode
                  </p>
                  <p className="text font-color-onyx m-0">
                    CLIFTON, NJ 07011-2118
                  </p>
                  <p className="text font-color-onyx m-0">United States</p>
                  <p className="text-small font-color-onyx">
                    <b>917 306 6033</b>
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <OrderDetailLabels order={order}/>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="bg-cloud border-radius-md mt-24">
              <Row>
                <Col span={24}>
                  <h3 className="text font-color-onyx mt-0">
                    <b>Note</b>
                  </h3>
                  {/* <p className="text font-color-onyx m-0">{order?.note}</p> */}
                  <p className="text font-color-onyx m-0">order?.note</p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col>
        <Divider type="vertical" className="vertical-divider" />
      </Col>
      <Col xl={15} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">Package Details</h3>
          </Col>
          <Col span={24}>
            {order?.items.map((item: any, index: any) => (
              <FBAShipmentItemCardManager
                item={item}
                key={index}
                order={order}
                setQty={setQty}
                orderItem={item}
              />
            ))}
          </Col>
          <Col span={24}>
            <OrderDetailPackageInfo order={order}/>
          </Col>
          <Col span={24}>
            <FBAShipmentCostDetail order={order}/>
          </Col>
          <Col span={24}>
            {!readOnly && (
              <Row justify={"end"} className="mt-24">
                <Popconfirm
                  title="Do you confirm the FBA shipment?"
                  onConfirm={confirmOrder}
                >
                  <Button className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-lg mt-24">
                    Confirm
                  </Button>
                </Popconfirm>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FBAShipmentDetailManager;
