import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Image,
  Input,
  Modal,
  Pagination,
  Progress,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import InventoryItemCard from "./InventoryItemCard";
import { useEffect, useState } from "react";
import InventoryService from "../../../services/InvertoryService";
import { NavLink, useLocation } from "react-router-dom";
import GetItemtoWarehouse from "./GetItemtoWarehouse";
import InventoryFilters from "./InventoryFilters";
import UserService from "../../../services/UserService";
import { formatDate, formatDateTime } from "../../../utils";
import type { PaginationProps } from "antd";
import { ColumnProps } from "antd/es/table";
import { CopyClipboard } from "../../FBACreate/CopyClipboard";
import closeIcon from "../../../assets/XCircle.svg";
import AmazonProductImage from "../../Common/AmazonProductImage";
import AmazonProductInfo from "../../Common/AmazonProductInfo";

const InventoryItemList = () => {
  const [inventoryList, setInventoryList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [searchText, setSearchText] = useState("");
  const [storageVolume, setStorageVolume] = useState<any>(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const [totalInventory, setTotalInventory] = useState(0);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText =
    location.state?.filterText ??
    `?page=${lastCurrentPage}&size=${lastPageSize}&userId=${selectedCustomer}&search=${searchText}`;
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  const [filterText, setFilterText] = useState<string>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState<any>(null);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(
      /(userId=)[^&]*/,
      `$1${selectedCustomer}`
    );
    newFilterText = newFilterText.replace(/(search=)[^&]*/, `$1${searchText}`);
    setFilterText(newFilterText);
  }, [currentPage, pageSize, selectedCustomer, searchText]);

  const onChangeSearch = (search: string) => {
    const params = new URLSearchParams(filterText);
    if (search) {
      params.set("search", search);
    } else {
      params.delete("search");
    }
    setCurrentPage(0);
    setPageSize(20);
    const newFilterText = "?" + params.toString();
    setFilterText(newFilterText);
  };

  const getInventoryList = () => {
    setLoading(true);
    InventoryService.getAllWithPagination(`${filterText}`)
      .then((response: any) => {
        let data = response.data.data;
        // data.sort(
        //   (a: any, b: any) =>
        //     new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        // );
        setInventoryList(data);
        setTotalInventory(response.data.totalElements);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (event: any) => {
    setSearchText(event.target.value);
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction = order === "ascend" ? "asc" : order === "descend" ? "desc" : "";
    const sortParamRegex = /&sort=([^&]*),([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);
    let newFilterText = filterText;
    if (sortMatch) {
      newFilterText = newFilterText.replace(sortParamRegex, `&sort=${field},${direction}`);
    } else if (field && direction) {
      newFilterText = `${newFilterText}&sort=${field},${direction}`;
    }
    setFilterText(newFilterText);
  };
  

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };
  

  useEffect(() => {
    getInventoryList();
  }, [filterText, sortField, sortOrder]);

  useEffect(() => {
    userGetAll();
    getTotalStorageVolume();
  }, []);

  const userGetAll = () => {
    UserService.getAll("?roleName=CUSTOMER")
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return {
            value: item.id,
            label: `#${item.id} ${item.firstName} ${item.lastName}`,
          };
        });
        setCustomerList(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getTotalStorageVolume = () => {
    InventoryService.getTotalStorageVolume()
      .then((response: any) => {
        let data = response.data;
        let total = Math.ceil(data);
        let percent = (data / total) * 100;
        setStorageVolume(percent.toFixed(0));
        setTotalStorage(total);
      })
      .catch((e: Error) => {});
  };

  const currentRole = localStorage.getItem("userRole");

  const showDetailModal = (details: any) => {
    const sortedDetails = details
    setSelectedItemDetails(sortedDetails);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      sorter: true,
      render: (text: string) => <span>{formatDateTime(text)}</span>,
    },
    {
      title: "Customer",
      dataIndex: "user",
      key: "user",
      ellipsis: { showTitle: false },
      width: 150,
      sorter: true,
      render: (text: string, record: any) => (
        <Tooltip
          placement="topLeft"
          title={
            record?.user.firstName +
            " " +
            record?.user.lastName +
            " #" +
            record?.user.id
          }
        >
          <p style={{ margin: 0 }}>
            {record?.user?.firstName} {record?.user?.lastName}
          </p>
          <b>#{record?.user?.id}</b>
        </Tooltip>
      ),
    },
    {
      title: "Items",
      dataIndex: "asin",
      key: "asin",
      width: 220,
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <Tooltip
            title={record?.product?.name}
          >
            <span>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <NavLink
                    to={`https://www.amazon.com/dp/${record?.product?.asin}`}
                    target="_blank"
                    style={{ marginRight: "10px" }}
                  >
                    <Image
                      style={{
                        borderRadius: "50%",
                        border: "3px solid rgba(51,62,72,0.5)",
                      }}
                      height={"40px"}
                      width={"40px"}
                      preview={false}
                      src={`https://api.oneamz.com/product-photo/${
                        record?.product?.asin
                      }?Authorization=${localStorage.getItem(
                        "externalAccessToken"
                      )}`}
                    />
                  </NavLink>
                  <CopyClipboard text={record?.product?.asin} />
                </div>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: <p style={{ margin: 0, textAlign: "center" }}>Dimensions</p>,
      dataIndex: "dimensions",
      key: "dimensions",
      width: 170,
      // sorter: true,
      render: (text: string, record: any) => {
        return (
          <p style={{ margin: 0, textAlign: "center" }}>
            {record?.product?.length} X {record?.product?.width} X {record?.product?.height} IN
            <p style={{ margin: 0 }}>{record?.product?.weight} LB</p>
          </p>
        );
      },
    },
    {
      title: <p style={{ margin: 0, textAlign: "center" }}>Quantity</p>,
      dataIndex: "qty",
      key: "qty",
      width: 100,
      sorter: true,
      render: (text: number, record: any) => <p style={{ margin: 0, textAlign: "center" }}>{record?.totalQty} PCS</p> || 0,
    },
    {
      title: <p style={{ margin: 0, textAlign: "center" }}>Show Details</p>,
      key: "showDetails",
      width: 150,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>
          <Button
          className="btn btn-hover-dark bg-cloud font-color-slate-blue border-radius-lg"
          style={{ fontWeight: 600 }}
          type="primary"
          onClick={() => showDetailModal(record)}
        >
          Show Details
        </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row gutter={24}>
        {currentRole == "CUSTOMER" && (
          <Col className="mt-24" xl={7} xs={24}>
            <NavLink to={"/storage/new"}>
              <GetItemtoWarehouse />
            </NavLink>
          </Col>
        )}
        <Col xl={6}>
          <Card className="bg-cloud border-radius-md mt-24">
            <p className="mt-0 text fs-18 font-color-slate-blu mb-0">
              <b>STORAGE AREA</b>
            </p>
            <Progress
              className="progress-slate-blue-2 mt-8"
              percent={100}
              size={["100%", 14]}
              success={{ percent: storageVolume }}
              showInfo={false}
            />
            {/* <Progress className='progress-slate-blue' percent={storageVolume} showInfo={false} /> */}
            <p className="text font-color-slate-blue fs-14 mb-0 mt-8">
              The <b>{totalStorage}</b> Storage Box is using{" "}
              <b>{storageVolume > 0 && storageVolume}.</b>
            </p>
          </Card>
        </Col>
        <Col className="mt-24" xl={currentRole == "CUSTOMER" ? 11 : 18} xs={24}>
          <Card className="bg-cloud border-radius-md h-full">
            <Row align={"middle"} gutter={24}>
              {currentRole != "CUSTOMER" ? (
                <Col className="mt-16" xxl={12} md={12} xs={24}>
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    className="amz-input w-full"
                    options={[{ value: "", label: "Select" }, ...customerList]}
                    showSearch
                    onChange={(customer) => setSelectedCustomer(customer)}
                    placeholder={"Customer"}
                  ></Select>{" "}
                </Col>
              ) : (
                <></>
              )}
              <Col className="mt-16" span={currentRole == "CUSTOMER" ? 24 : 12}>
                <Input
                  className="amz-input"
                  placeholder="You can search by ASIN or Product name"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <Card loading={loading} className="bg-cloud border-radius-md">
            <Row>
              <Col span={24}>
                <h3 className="h-3 m-0">Inventory List</h3>
              </Col>
              <Col className="antd-card-body-p-0" span={24}>
                <Table
                  loading={loading}
                  rowKey={(record) => record?.id}
                  key={"id"}
                  columns={columns}
                  dataSource={inventoryList}
                  bordered
                  scroll={{ x: 1000 }}
                  pagination={false}
                  onChange={handleTableChange}
                />
                <Row style={{ marginTop: "30px" }} justify={"center"}>
                  <Pagination
                    showSizeChanger={true}
                    total={totalInventory}
                    defaultPageSize={pageSize}
                    defaultCurrent={0}
                    current={currentPage + 1}
                    showQuickJumper
                    onChange={paginationOnChange}
                  />
                </Row>
              </Col>
            </Row>
          </Card>
          <Modal
            title="Stock History"
            open={isModalVisible}
            className="antd-modal-secondary "
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            closeIcon={<img src={closeIcon} height={24} alt="close" />}
          >
            <Row>
              <Col span={24}>
                <Card className="border-radius-md w-full p-24 mt-24 antd-card-body-p-0">
                  <Row gutter={24}>
                    <Col span={6}>
                      <AmazonProductImage
                       asin={selectedItemDetails?.productDetail?.asin}
                      ></AmazonProductImage>
                    </Col>
                    <Col span={18}>
                      <AmazonProductInfo
                       asin={selectedItemDetails?.productDetail?.asin}
                     title={selectedItemDetails?.productDetail?.title}
                      ></AmazonProductInfo>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row className="mt-24" justify={"space-between"} gutter={24}>
              <Col>
                <p className="text font-color-slate-blue">
                  <b>Number of Added / Subtracted Products</b>
                </p>
              </Col>
              <Col>
                <p className="text font-color-slate-blue">
                  <b>Updated Date</b>
                </p>
              </Col>
            </Row>
            {selectedItemDetails?.details?.map((detail: any) => (
          <Row justify={"space-between"}>
            <Col>
              {detail.qty > 0 ? (
                <p>
                  Added to inventory:{" "}
                  <b className="font-color-success">+{detail.qty}</b>
                </p>
              ) : (
                <p>
                  Sent from Inventory:{" "}
                  <b className="font-color-error">{detail.qty}</b>
                </p>
              )}
            </Col>
            <Col>
              <p className="text fs-14">{formatDateTime(detail.createdAt)}</p>
            </Col>
            <Divider className="m-0"></Divider>
          </Row>
        ))}
            <Row>
              <Col span={24}>
                <Button
                  onClick={() => setIsModalVisible(false)}
                  className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud w-full border-radius-lg mt-24"
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default InventoryItemList;
