import { Card, Col, Divider, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

interface OrderDetailPackageInfoProps {
  order: any;
}

class Product {
  key: number;
  color: string;
  asin: string;
  name: string;
  width: number;
  height: number;
  depth: number;
  weight: number;
  quantity: number;

  constructor(
    key: number,
    color: string,
    asin: string,
    name: string,
    width: number,
    height: number,
    depth: number,
    weight: number,
    quantity: number
  ) {
    this.key = key;
    this.color = color;
    this.asin = asin;
    this.name = name;
    this.width = width;
    this.height = height;
    this.depth = depth;
    this.weight = weight;
    this.quantity = quantity;
  }
}

class Box {
  products: Product[] = [];
  maxWidth: number = 24; // inches
  maxHeight: number = 24; // inches
  maxDepth: number = 24; // inches
  maxWeight: number = 45; // lbs

  getTotalWeight(): string {
    const totalWeight = this.products.reduce(
      (sum, p) => sum + p.weight * p.quantity,
      0
    );
    return totalWeight.toFixed(2); // Toplam ağırlığı iki ondalık basamakla yuvarla
  }

  getDesi(): string {
    const divisionNumber = 5000; // Bu değer değişebilir
    const totalDesi = this.products.reduce((sum, p) => {
      const volumeInInches = p.width * p.height * p.depth; // Hacmi inç küp olarak hesapla
      const volumeInCm = volumeInInches * 16.387; // inç küpü santimetre küpe çevir
      return sum + (volumeInCm / divisionNumber) * p.quantity;
    }, 0);
    return totalDesi.toFixed(2);
  }

  canAddProduct(product: Product): boolean {
    let currentWeight = this.products.reduce(
      (sum, p) => sum + p.weight * p.quantity,
      0
    );
    if (currentWeight + product.weight * product.quantity > this.maxWeight) {
      return false;
    }

    let usedVolume = this.products.reduce(
      (sum, p) => sum + p.width * p.height * p.depth * p.quantity,
      0
    );
    let newProductVolume =
      product.width * product.height * product.depth * product.quantity;
    let maxVolume = this.maxWidth * this.maxHeight * this.maxDepth;

    return usedVolume + newProductVolume <= maxVolume;
  }

  addProduct(product: Product): boolean {
    if (this.canAddProduct(product)) {
      this.products.push(product);
      return true;
    }
    return false;
  }
}

const OrderDetailPackageInfo: React.FC<OrderDetailPackageInfoProps> = ({
  order,
}) => {
  const [boxes, setBoxes] = useState<Box[]>([]);
  const [generalServices, setGeneralServices] = useState<any[]>([]);
  const [orderItems, setOrderItems] = useState<any[]>([]);
  const [totalOrderServiceFree, setTotalOrderServiceFee] = useState(0);

  useEffect(() => {
    console.log("orderorderorder", order);

    let orderGeneralServicesList: any[] = [];
    let orderItemList: any[] = [];

    if (order?.claimOrderId === undefined) {
      orderGeneralServicesList = order?.generalServices;
      orderItemList = order?.items;
    } else {
      orderGeneralServicesList = order?.generalServices;
      orderItemList = order?.items;
    }

    let orderServiceFree = 0;
    orderGeneralServicesList?.map((service: any) => {
      if (service.qty > 0) {
        orderServiceFree += service.amount;
      }
    });

    setTotalOrderServiceFee(orderServiceFree);
    setGeneralServices(orderGeneralServicesList);
    setOrderItems(orderItemList);
  }, [order]);

  const handlePacking = (products: Product[]) => {
    let tempBoxes: Box[] = [];
    products.forEach((product) => {
      for (let i = 0; i < product.quantity; i++) {
        let added = false;
        for (let box of tempBoxes) {
          if (
            box.addProduct(
              new Product(
                product.key,
                product.color,
                product.asin,
                product.name,
                product.width,
                product.height,
                product.depth,
                product.weight,
                1
              )
            )
          ) {
            added = true;
            break;
          }
        }
        if (!added) {
          let newBox = new Box();
          newBox.addProduct(
            new Product(
              product.key,
              product.color,
              product.asin,
              product.name,
              product.width,
              product.height,
              product.depth,
              product.weight,
              1
            )
          );
          tempBoxes.push(newBox);
        }
      }
    });
    setBoxes(tempBoxes);
  };

  useEffect(() => {
    let productList: Product[] = [];
    orderItems?.map((orderItem: any, index: number) => {
      productList.push(
        new Product(
          index + 1,
          orderItem.product.color,
          orderItem.product.asin,
          orderItem.product.name,
          orderItem.product.width,
          orderItem.product.height,
          orderItem.product.length,
          orderItem.product.weight,
          orderItem.qty
        )
      );
    });
    handlePacking(productList);
  }, [orderItems]);

  return (
    <>
      <Card className="border-radius-md border-slate-blue-02">
        <h3 className="h-3 m-0">Package Information</h3>
        <Row className="mt-24">
          <Col span={24}>
            <Row>
              {boxes?.map(
                (box, index) =>
                  index < 10 && (
                    <Col xl={12} key={index}>
                      <Row
                        gutter={[16, 16]}
                        justify={"start"}
                        className="mt-24"
                      >
                        <Col>
                          <div
                            className="package-box"
                            style={{ minHeight: "100%" }}
                          >
                            {box.products.map((product, pIndex) => (
                              <Tooltip
                                key={pIndex}
                                placement="top"
                                title={
                                  <>
                                    <b>Product:</b> {product.asin} <br />
                                    {product.width} x {product.height} x{" "}
                                    {product.depth}, Weight: {product.weight} lb
                                  </>
                                }
                              >
                                <div className="amz-product-box"></div>
                              </Tooltip>
                            ))}
                          </div>
                        </Col>
                        <Col span={8}>
                          <h3 className="text m-0">
                            <b>Package {index + 1}</b>
                          </h3>
                          <p className="text-small font-color-slate-blue-06 mt-16 mb-0">
                            <b>{box.products[0]?.asin} </b>
                          </p>
                          <Tooltip title={box.products[0]?.name}>
                            <p className="text-small m-0 font-color-slate-blue-06 product-title fs-12">
                              <b>{box.products[0]?.name} </b>
                            </p>
                          </Tooltip>
                          <h4 className="text mt-8 mb-0">
                            <b>{box.getTotalWeight()} LB</b>
                          </h4>
                          <h4 className="text m-0 fs-12 ">
                            <b>24X24X24"</b>
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  )
              )}
              {boxes.length > 10 && (
                <h3 className="mt-24">ve {boxes.length - 10} box</h3>
              )}
            </Row>
          </Col>
          {/* <Col span={24}>
            <Divider></Divider>
            <Row justify={'space-between'}>
              <Col>
                <p className='text fs-12 font-color-slate-blue-05'><b>TOPLAM KOLİ MİKTARI </b></p>
                <p className='text fs-12 font-color-slate-blue-05'><b>{boxes.length} Adet Koli</b></p>
              </Col>
              <Col>
                <p className='text fs-12 font-color-slate-blue-05'><b>TOPLAM KOLİ MALİYETİ </b></p>
                <p className='text fs-12 font-color-slate-blue-05'><b>${totalOrderServiceFree}</b></p>
              </Col>
              <Col>
                <p className='text fs-12 font-color-slate-blue-05'><b>TOPLAM MALİYET</b></p>
                <p className='text fs-12 font-color-slate-blue-05'><b>${totalOrderServiceFree}</b></p>
              </Col>
            </Row>
            <h4>Hizmetler</h4>
            {generalServices?.map((service: any, index: any) => (
              (service.qty > 0) ?
                <Row justify={"space-between"} key={index} align={"middle"}>
                  <Col>{service.generalService.name} </Col>
                  <Col><b>{service.qty} x ${service.amount} = ${service.amount * service.qty}</b></Col>
                </Row>
                : <></>
            ))} 
          </Col> */}
        </Row>
      </Card>
    </>
  );
};

export default OrderDetailPackageInfo;
