import React, { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import CustomersStarterCard from "../../components/V2/CustomersV2/CustomersStarterCard";
import { Card, Col, Input, Row } from "antd";
import CustomersCard from "../../components/V2/CustomersV2/CustomersCard";
import { SearchOutlined } from "@ant-design/icons";

const CustomersV2 = () => {
  const [customerList, setCustomerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const getAll = () => {
    setLoading(true);
    UserService.getAll("?roleName=CUSTOMER")
      .then((response: any) => {
        setCustomerList(response.data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAll();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    filterCustomers(value);
  };

  const filterCustomers = (query: string) => {
    if (!query) {
      // Eğer arama sorgusu boş ise tüm müşterileri getir
      getAll();
    } else {
      // Basit bir filtreleme işlemi, ihtiyaçlarınıza göre daha karmaşık filtrelemeler yapabilirsiniz
      const filtered = customerList.filter(
        (customer: any) =>
          customer.firstName?.toLowerCase().includes(query.toLowerCase()) ||
          customer.lastName?.toLowerCase().includes(query.toLowerCase()) ||
          customer.id?.toString().includes(query)
      );
      setCustomerList(filtered);
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <CustomersStarterCard />
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <Card loading={loading} className="bg-cloud border-radius-md px-16">
            <Row>
              <Col className="mb-24" xxl={6} md={12} xs={24}>
                <Input
                  className="amz-input"
                  placeholder="Search by Customer Name or ID"
                  prefix={<SearchOutlined className="fs-24 mx-8" />}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              {customerList.map((user) => (
                <CustomersCard user={user} />
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CustomersV2;
