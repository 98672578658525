import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Image, Checkbox, Card } from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import loginLogo from "../assets/oneship-logo.png";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import { openNotification } from "../utils";
import axios from "axios";
import moment from "moment";

const LoginPage: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getToken = (externalToken: string) => {
    let data = {
      externalToken: externalToken,
    };

    AuthService.authToken(data)
      .then((response: any) => {
        let accessToken = response.data.accessToken;
        let refreshToken = response.data.refreshToken;
        let accessTokenExpiredAt = response.data.accessTokenExpiredAt;
        let refreshTokenExpiredAt = response.data.refreshTokenExpiredAt;
        const currentTime = moment();

        console.log("currentTime", currentTime);

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("accessTokenExpiredAt", accessTokenExpiredAt);
        localStorage.setItem("refreshTokenExpiredAt", refreshTokenExpiredAt);
        localStorage.setItem(
          "lastCheckTime",
          currentTime.format("YYYY-MM-DD HH:mm:ss")
        );

        UserService.getMe().then((response: any) => {
          const data = response.data;
          localStorage.setItem("firstName", data.firstName);
          localStorage.setItem("lastName", data.lastName);
          localStorage.setItem("id", data.id);
          localStorage.setItem("email", data.email);
          localStorage.setItem("storageLevel", data.storageLevel);
          localStorage.setItem("userRole", "CUSTOMER");
          if (data.roles.length > 0) {
            let role = data.roles[0].name;
            localStorage.setItem("userRole", role);
          }

          navigate("/");
        });
      })
      .catch((e: Error) => {
        openNotification(
          "error",
          "Failed",
          "Email could not be verified contact administrator"
        );
        setLoading(false);
      });
  };

  const SignIn = (values: any) => {
    setLoading(true);
    let userData: any = {
      username: values.email,
      password: values.password,
      source: "fba",
    };

    const apiUrl = process.env.REACT_APP_EXTERNAL_API_BASE_URL + "/auth/login";

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      data: userData,
    };

    axios
      .request(config)
      .then((response) => {
        let externalAccessToken = response.data.accessToken;
        let externalRefreshToken = response.data.refreshToken;
        localStorage.setItem("externalAccessToken", externalAccessToken);
        localStorage.setItem("externalRefreshToken", externalRefreshToken);
        getToken(externalAccessToken);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        openNotification("error", "Failed", "Email or password incorrect");
      });
  };

  const onFinish = (values: any) => {
    SignIn(values);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="login-layout">
        <Row justify={"end"}>
          {/* <Col xs={0} sm={0} md={0} lg={12} xl={12}>
                    </Col> */}
          <Col xl={12} lg={16} md={24} sm={24} xs={24}>
            <Row
              align={"middle"}
              style={{ height: "100vh" }}
              justify={"center"}
            >
              <Col xl={12}>
                <Card className="border-radius-md">
                  <Row
                    justify={"center"}
                    style={{ margin: "5em 0 0 0", width: "100%" }}
                  >
                    <Col>
                      <Image
                        className="mt-24 mb-48"
                        style={{ width: "190px" }}
                        preview={false}
                        src={loginLogo}
                      />
                    </Col>
                  </Row>
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                  >
                    <h1 className="h-2">Sign in</h1>
                    <p style={{ margin: "0 0 0.4em 0", fontSize: "1.1em" }}>
                      Email
                    </p>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="exampleoneamz@gmail.com"
                        size="large"
                        style={{ padding: "0.7em" }}
                      />
                    </Form.Item>
                    <p style={{ margin: "0 0 0.4em 0", fontSize: "1.1em" }}>
                      Password
                    </p>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        style={{ padding: "0.7em" }}
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        placeholder="*************"
                        iconRender={(visible) =>
                          visible ? (
                            <EyeTwoTone onClick={togglePasswordVisibility} />
                          ) : (
                            <EyeInvisibleOutlined
                              onClick={togglePasswordVisibility}
                            />
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Row justify={"space-between"}>
                        <Form.Item
                          name="remember"
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox className="amz-checkbox">
                            <p style={{ fontSize: "1.2em", margin: "0" }}>
                              Remember Me Now
                            </p>
                          </Checkbox>
                        </Form.Item>
                        <a
                          className="login-form-forgot"
                          href="https://app.oneamz.com/auth/forget-password"
                        >
                          <p className="link font-color-slate-blue m-0">
                            Forgot password
                          </p>
                        </a>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="btn bg-slate-blue btn-hover-white btn-text border-radius-8 w-full"
                        loading={loading} // Butonun yükleme durumunu kontrol eder
                      >
                        <b className="font-color-cloud">Sign in</b>
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LoginPage;
