import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Dropdown, Image, Input, Menu, Pagination, Popconfirm, Row, Select, Table, Tag, Tooltip } from "antd";
import type { PaginationProps } from "antd";
import { ColumnProps } from "antd/es/table";
import { EllipsisOutlined } from "@ant-design/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { formatDate, formatDateTime, getDateRangeDefault, openNotification } from "../../../utils";
import { debounce } from "lodash";
import DateRange from "../../Common/DateRange";
import { CopyClipboard } from "../../FBACreate/CopyClipboard";
import CreateFbmCard from "./CreateFbmCard";
import UserService from "../../../services/UserService";
import { OrderStatus, OrderStatusDetails } from "../../../enums/OrderStatus";
import { IFbmOrderResponse } from "../../../types/FbmOrder";
import FbmOrderService from "../../../services/FbmOrderService";
import ClaimOrderService from "../../../services/ClaimOrderService";

const FbmShipmentList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<{ value: number; label: string }[]>([]);
  const [orderList, setOrderList] = useState<IFbmOrderResponse[]>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [selectedCustomer, setSelectedCustomer] = useState<number | string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [selectedStatuses, setSelectedStatuses] = useState<OrderStatus[]>([]);
  const [dateRange, setDateRange] = useState<any[]>(getDateRangeDefault);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  const currentRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("id");
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText = () => {
    if (currentRole === "CUSTOMER") {
      return `?page=${lastCurrentPage}&size=${lastPageSize}&userId=${userId}&startDate=${formatDate(dateRange[0])}T00:00:00&endDate=${formatDate(dateRange[1])}T23:59:59&statuses=${selectedStatuses.join(",")}&search=${searchText}`;
    } else {
      return `?page=${lastCurrentPage}&size=${lastPageSize}&userId=${selectedCustomer}&startDate=${formatDate(dateRange[0])}T00:00:00&endDate=${formatDate(dateRange[1])}T23:59:59&statuses=${selectedStatuses.join(",")}&search=${searchText}`;
    }
  };
  const [filterText, setFilterText] = useState<any>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);

  const defaultOption = { value: "", label: "Select", color: "default" };

  const statusList = [
    defaultOption,
    ...Object.keys(OrderStatusDetails).map((key) => ({
      value: key as OrderStatus,
      label: OrderStatusDetails[key as OrderStatus].label,
    })),
  ];

  const statusColors = {
    "": defaultOption.color,
    ...Object.keys(OrderStatusDetails).reduce((acc, key) => {
      acc[key as OrderStatus] = OrderStatusDetails[key as OrderStatus].color;
      return acc;
    }, {} as Record<OrderStatus, string>),
  };

  const statusLabels = {
    "": defaultOption.label,
    ...Object.keys(OrderStatusDetails).reduce((acc, key) => {
      acc[key as OrderStatus] = OrderStatusDetails[key as OrderStatus].label;
      return acc;
    }, {} as Record<OrderStatus, string>),
  };

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(/(statuses=)[^&]*/, `$1${selectedStatuses.join(",")}`);
    newFilterText = newFilterText.replace(/(startDate=)[^&]*/, `$1${formatDate(dateRange[0])}T00:00:00`);
    newFilterText = newFilterText.replace(/(endDate=)[^&]*/, `$1${formatDate(dateRange[1])}T23:59:59`);
    newFilterText = newFilterText.replace(/(search=)[^&]*/, `$1${searchText}`);
    newFilterText = newFilterText.replace(/(userId=)[^&]*/, currentRole === "CUSTOMER" ? `$1${userId}` : `$1${selectedCustomer}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [currentPage, pageSize, selectedStatuses, selectedCustomer, dateRange, searchText, sortField, sortOrder]);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchText(value);
    }, 500)
  ).current;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debouncedSearch(value);
  };

  const getOrderList = () => {
    setLoading(true);
    FbmOrderService.getFbmOrders(filterText)
      .then((response) => {
        const orders = response.data.data.map(
          (order) => new IFbmOrderResponse(order)
        );
        setOrderList(orders);
        setTotalOrders(response.data.totalElements);
      })
      .catch((e: Error) => {
        console.log(e)
        openNotification("error", "Error", "Failed to fetch orders");
      })
      .finally(() => setLoading(false));
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction = order === "ascend" ? "ASC" : order === "descend" ? "DESC" : undefined;
    const sortParamRegex = /(&sort=[^&]*)|(&direction=[^&]*)/g;
    let newFilterText = filterText.replace(sortParamRegex, "");
    if (field && direction) {
      newFilterText += `&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getOrderList();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const getAllUser = () => {
    setLoading(true);
    UserService.getAll("?roleName=CUSTOMER")
      .then((response) => {
        let data = response.data;
        const newArray = data?.map((item: any) => {
          return {
            value: item?.id,
            label: `#${item?.id} ${item?.firstName} ${item?.lastName}`,
          };
        });
        setCustomerList(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!customerList?.length && currentRole === "ADMIN") {
      getAllUser();
    }
    // eslint-disable-next-line
  }, [customerList]);

  const handleCancel = (id: any) => {
    setLoading(true);
    ClaimOrderService.cancel(id)
      .then((response: any) => {})
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnProps<IFbmOrderResponse>[] = [
    {
      title: "Order Code",
      dataIndex: "orderCode",
      key: "orderCode",
      width: 150,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <span>{text}</span>
        </NavLink>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      sorter: true,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <span>{formatDateTime(text)}</span>
        </NavLink>
      ),
    },
    {
      title: "Customer",
      dataIndex: "user",
      key: "user",
      ellipsis: { showTitle: false },
      width: 150,
      sorter: true,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <Tooltip
            placement="topLeft"
            title={`${record?.user?.firstName} ${record?.user?.lastName} #${record?.user?.id}`}
          >
            <p style={{ margin: 0 }}>
              {record?.user?.firstName} {record?.user?.lastName}
            </p>
            <b>#{record?.user?.id}</b>
          </Tooltip>
        </NavLink>
      ),
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: 220,
      render: (text, record) => (
        <Tooltip
          title={record?.items
            ?.map((item) => item?.productDetail?.title)
            .join(", ")}
        >
          <span>
            {record?.items?.map((item, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <NavLink
                  to={`https://www.amazon.com/dp/${item?.productDetail?.asin}`}
                  target="_blank"
                  style={{ marginRight: "10px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `https://www.amazon.com/dp/${item?.productDetail?.asin}`,
                      "_blank"
                    );
                  }}
                >
                  <Image
                    style={{
                      borderRadius: "50%",
                      border: "3px solid rgba(51,62,72,0.5)",
                    }}
                    height={"40px"}
                    width={"40px"}
                    preview={false}
                    src={`https://api.oneamz.com/product-photo/${
                      item?.productDetail?.asin
                    }?Authorization=${localStorage.getItem(
                      "externalAccessToken"
                    )}`}
                  />
                </NavLink>
                <CopyClipboard text={item?.productDetail?.asin} />
              </div>
            ))}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Received/Total QTY",
      dataIndex: "items",
      key: "qty",
      align: "center",
      width: 150,
      // sorter: (a, b) => {
      //   const getReceivedQty = (items: any[]) =>
      //     items.reduce((acc, item) => acc + (item?.received || 0), 0);
      //   const aReceivedQty = getReceivedQty(a.items);
      //   const bReceivedQty = getReceivedQty(b.items);
      //   return aReceivedQty - bReceivedQty;
      // },
      render: (text, record) => {
        const totalQty = record?.items?.reduce(
          (a, b) => a + (b?.quantity || 0),
          0
        );
        const receivedQty = record?.items?.reduce((a, b) => a + (0 || 0), 0);

        return (
          <NavLink to={record?.detailUrl} style={{ color: "black" }}>
            <span>
              {receivedQty} / {totalQty} PCS
            </span>
          </NavLink>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      sorter: true,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <Tag
            color={statusColors[record?.status] || "inherit"}
            style={{
              fontWeight: "500",
              whiteSpace: "wrap",
              textAlign: "center",
            }}
          >
            {statusLabels[record?.status] || record?.status}
          </Tag>
        </NavLink>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (text, record) =>
        currentRole !== "CUSTOMER" && record.status !== "WAITING" ? (
          <Button
            type="link"
            style={{ color: "black", border: "1px solid black" }}
            onClick={() => {
              navigate(`${record?.detailUrl}`);
            }}
          >
            View
          </Button>
        ) : (
          <Dropdown
            placement="topRight"
            overlay={
              <Menu>
                <Menu.Item>
                  <NavLink to={record?.detailUrl}>View Details</NavLink>
                </Menu.Item>
                <Popconfirm
                  title="Are you sure you want to delete this order?"
                  onConfirm={() => handleCancel(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Menu.Item>Delete</Menu.Item>
                </Popconfirm>
              </Menu>
            }
          >
            <EllipsisOutlined rotate={90} style={{ fontSize: "24px", cursor: "pointer" }} />
          </Dropdown>
        ),
    },
  ];

  const renderCard = () => {
    return (
      <>
        <Table
          loading={loading}
          rowKey={"id"}
          columns={columns}
          dataSource={orderList}
          bordered
          scroll={{ x: 1000 }}
          pagination={false}
          onChange={handleTableChange}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalOrders}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </>
    );
  };

  return (
    <>
      <Row gutter={24}>
        {currentRole === "CUSTOMER" && (
          <Col className="mt-24" xl={8} xs={24}>
            <NavLink to={"/fbm/new"}>
              <CreateFbmCard></CreateFbmCard>
            </NavLink>
          </Col>
        )}
        <Col className="mt-24" xl={currentRole === "CUSTOMER" ? 16 : 24} xs={24}>
          <Card className="bg-cloud border-radius-md h-full">
            <Row align={"middle"} gutter={24}>
              {currentRole !== "CUSTOMER" ? (
                <Col className="mt-16" xxl={6} md={12} xs={24}>
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    className="amz-input w-full"
                    options={[{ value: "", label: "Select" }, ...customerList]}
                    showSearch
                    onChange={(customer) => setSelectedCustomer(customer)}
                    placeholder={"Customer"}
                  />
                </Col>
              ) : (
                <></>
              )}
              <Col className="mt-16" xl={currentRole === "CUSTOMER" ? 8 : 6} md={12} xs={24}>
                <Input
                  className="amz-input w-full"
                  placeholder="Keyword"
                  onChange={handleChange}
                />
              </Col>
              <Col className="mt-16" xl={currentRole === "CUSTOMER" ? 8 : 6} md={12} xs={24}>
                <Select
                  className="amz-input w-full"
                  mode="multiple"
                  options={statusList}
                  onChange={(statuses) => setSelectedStatuses(statuses)}
                  placeholder={"Status"}
                />
              </Col>
              <Col className="mt-16" xl={currentRole === "CUSTOMER" ? 8 : 6} md={12} xs={24}>
                <DateRange onChangeDateRange={(dateRange) => setDateRange(dateRange)} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card className="bg-cloud border-radius-md mt-24" loading={loading}>
        <Row justify={"space-between"}>
          <Col xs={24} md={12}>
            <h3 className="h-3 m-0">FBM Shipment List</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>{renderCard()}</Col>
        </Row>
      </Card>
    </>
  );
};

export default FbmShipmentList;
