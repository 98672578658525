import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import ClaimOrderService from "../../services/ClaimOrderService";
import OrderService from "../../services/OrderService";
import { IResponseOrder } from "../../types/Order";
import { useNavigate } from "react-router-dom";
import { openNotification } from "../../utils";

function Search() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getOrderDetail = (id: string) => {
    OrderService.getClaimOrderId(id)
      .then((response: any) => {
        const data: IResponseOrder = new IResponseOrder(response.data);
        navigate(`/order/detail/${data.id}`);
        setLoading(false);
      })
      .catch((e: Error) => {
        getClaimOrderDetail(id);
      });
  };

  const getClaimOrderDetail = (id: string) => {
    ClaimOrderService.get(id)
      .then((response: any) => {
        const data: IResponseOrder = new IResponseOrder(response.data);
        navigate(`/claim/detail/${data.id}`);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
        openNotification("warning", "Warning", "No results found");
      });
  };

  const handleSubmit = (values: any) => {
    if (values.code) {
      setLoading(true);
      getOrderDetail(values.code);
    }
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="code">
          <Input.Search
            allowClear
            size="large"
            loading={loading}
            placeholder="Search Shipment"
            enterButton={
              <Button loading={loading} htmlType="submit">
                Search
              </Button>
            }
          />
        </Form.Item>
      </Form>
    </>
  );
}

export default Search;
