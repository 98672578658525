import http from "../http-common";
import { IRoleRequestData, IRoleResponseData } from "../types/Role";

const getAll = () => {
  return http.get<Array<IRoleResponseData>>("/api/v1/roles");
};
const get = (id: Number) => {
  return http.get<IRoleResponseData>(`/api/v1/roles/${id}`);
};

const create = (data: IRoleRequestData) => {
  return http.post<IRoleRequestData>("/api/v1/roles", data);
};

const update = (id: any, data: IRoleRequestData) => {
  return http.put<any>(`/api/v1/roles/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/api/v1/roles/${id}`);
};

const RoleService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default RoleService;
