import { Button, Card, Col, Drawer, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import WarehouseService from "../../services/WarehouseService";

interface WarehouseDetailProps {
  id: any;
  onHandleWarehouseList: () => void;
}

const { Item } = Form;

const WarehouseDetail: React.FC<WarehouseDetailProps> = ({
  id,
  onHandleWarehouseList,
}) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      getWarehouse();
    }
  }, [open]);

  const getWarehouse = () => {
    setLoading(true);
    WarehouseService.get(id)
      .then((response: any) => {
        let data = response.data;
        console.log(data);
        form.setFieldsValue({
          countryCode: data?.countryCode,
          type: data?.type,
          code: data?.code,
          address: data?.address,
          name: data?.name,
          addressLine1: data?.addressLine1,
          addressLine2: data?.addressLine2,
          city: data?.city,
          state: data?.state,
          postalCode: data?.postalCode,
          phone: data?.phone,
        });
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const update = (values: any) => {
    let postObj = {
      countryCode: values.countryCode,
      type: values.type,
      code: values.code,
      address: values.address,
      name: values?.name,
      addressLine1: values?.addressLine1,
      addressLine2: values?.addressLine2,
      city: values?.city,
      state: values?.state,
      postalCode: values?.postalCode,
      phone: values?.phone,
    };

    WarehouseService.update(id, postObj)
      .then((response: any) => {
        onHandleWarehouseList();
        // onClose();
      })
      .catch((e: Error) => {});
  };

  return (
    <div>
      <Button
        className="btn-sm btn-text-sm btn-hover-white bg-slate-blue font-color-cloud border-radius-8"
        onClick={showDrawer}
      >
        Detail
      </Button>
      <Drawer
        title="Edit Warehouse"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Card loading={loading}>
          <Form form={form} onFinish={update} layout="vertical">
            <Item
              name="name"
              label="Warehouse Name"
              rules={[
                {
                  required: true,
                  message: "Warehouse Name required",
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              name="countryCode"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Select Country",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={[
                  { label: "Canada", value: "CA" },
                  { label: "Mexico", value: "MX" },
                  { label: "US", value: "US" },
                  { label: "Czech Republic", value: "CZ" },
                  { label: "France", value: "FR" },
                  { label: "Germany", value: "DE" },
                  { label: "Italy", value: "IT" },
                  { label: "Poland", value: "PL" },
                  { label: "Spain", value: "ES" },
                  { label: "UK", value: "GB" },
                  { label: "India", value: "IN" },
                  { label: "Japan", value: "JP" },
                  { label: "Singapore", value: "SG" },
                  { label: "UAE", value: "AE" },
                  { label: "Australia", value: "AU" },
                ]}
              />
            </Item>
            <Item
              name="code"
              label="Warehouse Code"
              rules={[
                {
                  required: true,
                  message: "Warehouse Code required",
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Address required",
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              name="addressLine1"
              label="Address Line 1"
              rules={[
                {
                  required: true,
                  message: "Address Line 1 required",
                },
              ]}
            >
              <Input />
            </Item>
            <Item name="addressLine2" label="Address Line 2">
              <Input />
            </Item>
            <Item name="phone" label="Phone">
              <Input />
            </Item>
            <Item name="state" label="State">
              <Input />
            </Item>
            <Item name="city" label="City">
              <Input />
            </Item>
            <Item name="postalCode" label="Postal Code">
              <Input />
            </Item>
            <Item
              name="type"
              label="Warehouse Type"
              rules={[
                {
                  required: true,
                  message: "Select Warehouse Type!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={[
                  { value: "W", label: "Warehouse" },
                  { value: "FC", label: "Fulfillment Center" },
                ]}
              />
            </Item>
            <Item>
              <Row justify={"end"}>
                <Col>
                  <Button
                    className="btn btn-text btn-hover-dark"
                    onClick={onClose}
                    style={{ marginRight: "10px" }}
                  >
                    Give up
                  </Button>
                </Col>
                <Button
                  className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-8"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Row>
            </Item>
          </Form>
        </Card>
      </Drawer>
    </div>
  );
};

export default WarehouseDetail;
