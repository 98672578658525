import { Avatar, Col, Collapse, Divider, Row, Image } from "antd";
import React from "react";
import { IProductResponse } from "../../../types/Product";
import OrderAlert from "../../Order/OrderAlert";
import { NavLink } from "react-router-dom";

interface OrderDetailPackageInfoProps {
  item: any;
}
const OrderDetailPackageInfo: React.FC<OrderDetailPackageInfoProps> = ({
  item,
}) => {
  const displayText =
    item.product.name.length > 100
      ? `${item.product.name.substring(0, 100)}...`
      : item.product.name;
  const mandatoryServices = item.services.filter(
    (service: any) => !service.generalService.isOptional
  );
  const optionalServices = item.services.filter(
    (service: any) => service.generalService.isOptional
  );

  return (
    <>
      {/* <Card className='border-radius-md mt-24 antd-card-body-p-0 p-16 bg-cloud'> */}
      <Collapse
        className="antd-order-detail-collapse border-radius-md mt-24"
        expandIconPosition={"end"}
        items={[
          {
            key: "1",
            label: (
              <>
                <Row align={"middle"}>
                  <Col span={4}>
                    <Avatar
                      size={64}
                      src={
                        <Image
                          src={`https://api.oneamz.com/product-photo/${
                            item.product.asin
                          }?Authorization=${localStorage.getItem(
                            "externalAccessToken"
                          )}`}
                        />
                      }
                    ></Avatar>
                  </Col>
                  <Col span={10}>
                    <p className="text m-0">
                      <b>{item.product.asin}</b>
                    </p>
                    <Row>
                      <NavLink
                        to={`https://www.amazon.com/dp/${item.product.asin}`}
                        target="_blank"
                      >
                        <p className="text-small m-0 font-color-slate-blue-06">
                          <b>{displayText}</b>
                        </p>
                      </NavLink>
                    </Row>
                  </Col>
                  <Col className="text-center" xl={10}>
                    <OrderAlert order={item} />
                  </Col>
                </Row>
              </>
            ),
            children: (
              <>
                {/* <Divider className='slate-blue-divider'></Divider> */}
                <Row justify={"space-between"} align={"top"}>
                  <Col xl={11}>
                    <h4 className="text mt-0">
                      <b>FBA COMPULSORY SERVICES</b>
                    </h4>
                    <Row>
                      <Col span={9}>
                        <p className="text-small font-color-slate-blue">
                          <b>Service</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue">
                          <b>Unit Price</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue">
                          <b>Quantity</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue">
                          <b>Total</b>
                        </p>
                      </Col>
                    </Row>
                    {mandatoryServices.map((service: any) => (
                      <Row key={service.id}>
                        <Col span={9}>
                          <p className="text-small font-color-slate-blue-05">
                            <b>{service.generalService.name}</b>
                          </p>
                        </Col>
                        <Col className="text-center" span={5}>
                          <p className="text-small font-color-slate-blue-05">
                            <b>${service.generalService.fee}</b>
                          </p>
                        </Col>
                        <Col className="text-center" span={5}>
                          <p className="text-small font-color-slate-blue-05">
                            <b>{service.qty}</b>
                          </p>
                        </Col>
                        <Col className="text-center" span={5}>
                          <p className="text-small font-color-slate-blue">
                            <b>${service.amount}</b>
                          </p>
                        </Col>
                      </Row>
                    ))}
                  </Col>

                  <Col xl={11}>
                    <h4 className="text mt-0">
                      <b>ADDITIONAL SERVICES</b>
                    </h4>
                    <Row>
                      <Col span={9}>
                        <p className="text-small font-color-slate-blue">
                          <b>Service</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue">
                          <b>Unit Price</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue">
                          <b>Quantity</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue">
                          <b>Total</b>
                        </p>
                      </Col>
                    </Row>
                    {optionalServices.map((service: any) => (
                      <Row key={service.id}>
                        <Col span={9}>
                          <p className="text-small font-color-slate-blue-05">
                            <b>{service.generalService.name}</b>
                          </p>
                        </Col>
                        <Col className="text-center" span={5}>
                          <p className="text-small font-color-slate-blue-05">
                            <b>${service.generalService.fee}</b>
                          </p>
                        </Col>
                        <Col className="text-center" span={5}>
                          <p className="text-small font-color-slate-blue-05">
                            <b>{service.qty}</b>
                          </p>
                        </Col>
                        <Col className="text-center" span={5}>
                          <p className="text-small font-color-slate-blue">
                            <b>${service.amount}</b>
                          </p>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </>
            ),
          },
        ]}
      />
      {/* </Card > */}
    </>
  );
};

export default OrderDetailPackageInfo;
