import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import WarehouseService from "../../services/WarehouseService";
import closeIcon from "../../assets/XCircle.svg";
import { openNotification } from "../../utils";

interface WarehouseDetailProps {
  onHandleWarehouseList: () => void;
}

const { Item } = Form;

const CreateWarehouse: React.FC<WarehouseDetailProps> = ({
  onHandleWarehouseList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const countries = [
    { label: "Canada", value: "CA" },
    { label: "Mexico", value: "MX" },
    { label: "US", value: "US" },
    { label: "Czech Republic", value: "CZ" },
    { label: "France", value: "FR" },
    { label: "Germany", value: "DE" },
    { label: "Italy", value: "IT" },
    { label: "Poland", value: "PL" },
    { label: "Spain", value: "ES" },
    { label: "UK", value: "GB" },
    { label: "India", value: "IN" },
    { label: "Japan", value: "JP" },
    { label: "Singapore", value: "SG" },
    { label: "UAE", value: "AE" },
    { label: "Australia", value: "AU" },
  ];

  const warehouseType = [
    { value: "W", label: "Warehouse" },
    { value: "FC", label: "Fulfillment Center" },
  ];

  useEffect(() => {
    form.resetFields();
  }, [isModalVisible]);

  const update = (values: any) => {
    let postObj = {
      countryCode: values.countryCode,
      type: values.type,
      code: values.code,
      address: values.address,
      name: values?.name,
      addressLine1: values?.addressLine1,
      addressLine2: values?.addressLine2,
      city: values?.city,
      state: values?.state,
      postalCode: values?.postalCode,
      phone: values?.phone,
    };

    WarehouseService.create(postObj)
      .then((response: any) => {
        onHandleWarehouseList();
        openNotification(
          "success",
          "Success",
          "Warehouse Successfully Registered."
        );
        setIsModalVisible(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <>
      <Row justify={"end"}>
        <Col span={24}>
          <Button
            className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud w-full border-radius-8"
            onClick={() => setIsModalVisible(true)}
          >
            Add Warehouse
          </Button>
        </Col>
      </Row>
      <Modal
        title={<>Add Warehouse</>}
        visible={isModalVisible}
        className="antd-modal-secondary "
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={update} layout="vertical">
          <Item
            name="name"
            label="Warehouse Name"
            rules={[
              {
                required: true,
                message: "Warehouse name required",
              },
            ]}
          >
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item
            name="countryCode"
            label="Country"
            rules={[
              {
                required: true,
                message: "Select Country",
              },
            ]}
          >
            <Select
              className="amz-select-input border-slate-blue border-radius-8 amz-input"
              style={{ width: "100%" }}
              placeholder="Select Country"
              options={[
                { label: "Canada", value: "CA" },
                { label: "Mexico", value: "MX" },
                { label: "US", value: "US" },
                { label: "Czech Republic", value: "CZ" },
                { label: "France", value: "FR" },
                { label: "Germany", value: "DE" },
                { label: "Italy", value: "IT" },
                { label: "Poland", value: "PL" },
                { label: "Spain", value: "ES" },
                { label: "UK", value: "GB" },
                { label: "India", value: "IN" },
                { label: "Japan", value: "JP" },
                { label: "Singapore", value: "SG" },
                { label: "UAE", value: "AE" },
                { label: "Australia", value: "AU" },
              ]}
            />
          </Item>
          <Item name="code" label="Warehouse Code">
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item
            name="addressLine1"
            label="Address Line 1"
            rules={[
              {
                required: true,
                message: "Country Select",
              },
            ]}
          >
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item name="addressLine2" label="Address Line 2">
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item name="phone" label="Phone">
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item name="state" label="State">
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item name="city" label="City">
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item name="postalCode" label="Postal Code">
            <Input className="amz-input border-radius-8" />
          </Item>
          <Item
            name="type"
            label="Warehouse Type"
            rules={[
              {
                required: true,
                message: "Select Warehouse Type!",
              },
            ]}
          >
            <Select
              className="amz-select-input border-slate-blue border-radius-8 amz-input"
              style={{ width: "100%" }}
              placeholder="Select Warehouse Type"
              options={[
                { value: "W", label: "Warehouse" },
                { value: "FC", label: "Fulfillment Center" },
              ]}
            />
          </Item>
          <Item>
            <Row gutter={24}>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                  onClick={() => setIsModalVisible(false)}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateWarehouse;
