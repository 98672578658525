import http from "../http-common";

const create = (data: any) => {
  return http.post<any>(`/api/v1/users/me/claim-orders`, data);
};

const postFbaLabel = (id: any, data: any) => {
  return http.post<any>(`/api/v1/claim-orders/${id}/fba/upload`, data);
};

const getReport = (filter = "") => {
  return http.get<Array<any>>(`/api/v1/users/me/claim-orders/reports/daily${filter}`);
};

const getAll = (filter = "") => {
  let role = localStorage.getItem("userRole");
  if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
    return http.get<Array<any>>(`/api/v1/claim-orders${filter}`);
  }
  return http.get<Array<any>>(`/api/v1/users/me/claim-orders${filter}`);
};

const getAllWithPagination  = (filterCriteria: string = ''  ) => {
  let role = localStorage.getItem("userRole");
  if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
    return http.get<Array<any>>(`/api/v1/claim-orders${filterCriteria}`);
  }
  return http.get<Array<any>>(`/api/v1/users/me/claim-orders${filterCriteria}`);
}

const cancel = (claimOrderId: any) => {
  return http.put<any>(`/api/v1/claim-orders/${claimOrderId}/cancel`);
};

const addService = (claimOrderId: any, list:any) => {
  return http.post<any>(`/api/v1/claim-order-items/${claimOrderId}/services`, list);
};


const updateClaimOrderItem = (claimOrderId: any, updateObj: any) => {
  return http.patch<any>(`/api/v1/claim-order-items/${claimOrderId}`, updateObj);
};

const updateTrackingCode = (claimOrderId: any, trackingCode: any) => {
  const postObj = {
    trackingCode: trackingCode,
  };

  return http.patch<any>(`/api/v1/claim-order-items/${claimOrderId}`, postObj);
};

const updateNote = (claimOrderId: any, note: any) => {
  const postObj = {
    note: note,
  };

  return http.patch<any>(`/api/v1/claim-order-items/${claimOrderId}`, postObj);
};

const updateDamage = (claimOrderId: any, damaged: any) => {
  const postObj = {
    damaged: damaged,
  };

  return http.patch<any>(`/api/v1/claim-order-items/${claimOrderId}`, postObj);
};

const updateReceive = (claimOrderId: any, received: any) => {
  const postObj = {
    received: received,
  };

  return http.patch<any>(`/api/v1/claim-order-items/${claimOrderId}`, postObj);
};

const getFBALabel = (claimOrderId: any) => {
  return http.get<any>(`/api/v1/claim-orders/${claimOrderId}/fba/download`, {
    responseType: "arraybuffer",
  });
};

const getShippingLabel = (claimOrderId: any) => {
  return http.get<any>(
    `/api/v1/claim-orders/${claimOrderId}/shipping-label/download`,
    { responseType: "arraybuffer" }
  );
};

const getFnskuLabel = (claimOrderItemId: any) => {
  return http.get<any>(`/api/v1/claim-order-items/${claimOrderItemId}/fnsku/download`, {
    responseType: "arraybuffer",
  });
};

const getAllCount = (filter = "") => {
  let role = localStorage.getItem("userRole");
  if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
    return http.get<Array<any>>(`/api/v1/claim-orders/count${filter}`);
  }
  return http.get<Array<any>>(`/api/v1/users/me/claim-orders/count${filter}`);
};

const get = (id: any) => {
  return http.get<any>(`/api/v1/claim-orders/${id}`);
};

const getCustomer = (id: any) => {
  return http.get<any>(`/api/v1/claim-orders/${id}/customer`);
};

const getAsintoClaimOrders = (asin: string, userId: number) => {
  return http.get<any>(`/api/v1/claim-orders/asin-to-claim-orders?asin=${asin}&userId=${userId}`);
};

const ClaimOrderService = {
  get,
  getReport,
  getCustomer,
  cancel,
  postFbaLabel,
  getAll,
  getAllWithPagination,
  create,
  getAllCount,
  getFnskuLabel,
  getFBALabel,
  updateClaimOrderItem,
  updateTrackingCode,
  getShippingLabel,
  updateNote,
  updateDamage,
  updateReceive,
  addService,
  getAsintoClaimOrders
};

export default ClaimOrderService;

