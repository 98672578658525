import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Row } from "antd";
import { openNotification } from "../../utils";
import OrderDetailCard from "../../components/V2/OrderDetailV2/OrderDetailCard";
import OrderDetailStatus from "../../components/V2/OrderDetailV2/OrderDetailStatus";
import StorageDetail from "./OrderDetail/StorageDetail";
import StorageDetailManager from "./OrderDetail/StorageDetailManager";
import FBAShipmentDetail from "./OrderDetail/FBAShipmentDetail";
import FBAShipmentDetailManager from "./OrderDetail/FBAShipmentDetailManager";
import FBMShipmentDetail from "./OrderDetail/FBMShipmentDetail";
import FBMShipmentDetailManager from "./OrderDetail/FBMShipmentDetailManager";
import FbaOrderService from "../../services/FbaOrderService";
import FbmOrderService from "../../services/FbmOrderService";
import { IFbaOrderResponse } from "../../types/FbaOrder";
import { IFbmOrderResponse } from "../../types/FbmOrder";

const OrderDetail = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<any>();
  const [title, setTitle] = useState<string>("");
  let { id } = useParams();
  const location = useLocation();
  let currentRole = localStorage.getItem("userRole");

  const getOrderDetail = (id: number) => {
    console.log("id", id);
    setLoading(true);
    if (location.pathname.includes("/fba-order/detail")) {
      setTitle("FBA SHIPMENT DETAIL");
      FbaOrderService.getFbaOrderById(id)
        .then((response) => {
          setOrder(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (location.pathname.includes("/fbm-order/detail")) {
      setTitle("FBM SHIPMENT DETAIL");
      FbmOrderService.getFbmOrderById(id)
        .then((response) => {
          setOrder(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (location.pathname.includes("/storage-order/detail")) {
      // setTitle("WAREHOUSE SHIPMENT DETAILS");
      // StorageOrderService.getStorageOrderById(id)
      // .then((response) => {
      //   setOrder(response.data);
      // })
      // .catch((e: Error) => {
      //   console.log(e);
      //   openNotification("error", "Error", "Something went wrong");
      // })
      // .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    const orderId = Number(id);
    if (!isNaN(orderId)) {
      getOrderDetail(orderId);
    }
  }, [id]);

  const changeQty = (id: any, qty: any) => {
    if (qty === null) {
      qty = 0;
    }
    let data = order;
    let orderObj = order.items.map((updatedItem: any) => {
      if (updatedItem.id === id) {
        updatedItem.approvalQty = qty;
        return updatedItem;
      }
      return updatedItem;
    });

    data.items = orderObj;
    setOrder(data);
  };

  return (
    <>
      <OrderDetailCard title={title} order={order} />
      <Row className="mt-24">
        <Col span={24}>
          <OrderDetailStatus order={order} />
        </Col>
      </Row>
      {location.pathname.includes("/storage-order/detail") && (
        <>
          {currentRole === "CUSTOMER" && <StorageDetail order={order} />}
          {currentRole === "ADMIN" && (
            <StorageDetailManager order={order} setQty={changeQty} />
          )}
        </>
      )}
      {location.pathname.includes("/fba-order/detail") && (
        <>
          {currentRole === "CUSTOMER" && (
            <FBAShipmentDetail order={order} setQty={changeQty} />
          )}
          {currentRole === "ADMIN" && (
            <FBAShipmentDetailManager order={order} setQty={changeQty} />
          )}
        </>
      )}
      {location.pathname.includes("/fbm-order/detail") && (
        <>
          {currentRole === "CUSTOMER" && <FBMShipmentDetail order={order} />}
          {currentRole === "ADMIN" && (
            <FBMShipmentDetailManager order={order} setQty={changeQty} />
          )}
        </>
      )}
    </>
  );
};

export default OrderDetail;
