import http from "../http-common";
import { IBinRequest, IBinResponse } from "../types/Bin";

const getAll = () => {
  return http.get<Array<IBinResponse>>(`/api/v1/bins`);
};

const get = (binId: number) => {
  return http.get<IBinResponse>(`/api/v1/bins/${binId}`);
};

const update = (binId: any, data: IBinRequest) => {
  return http.put<any>(`/api/v1/bins/${binId}`, data);
};

const create = (data: IBinRequest) => {
  return http.post<any>(`/api/v1/bins`, data);
};

const remove = (binId: any) => {
  return http.delete<any>(`/api/v1/bins/${binId}`);
};

const BinService = {
  getAll,
  get,
  update,
  create,
  remove,
};

export default BinService;
