import http from "../http-common";
import { IProductRequest, IProductResponse } from "../types/Product";

const getAll = () => {
  return http.get<Array<IProductResponse>>(`/api/v1/products`);
};

const getAllCount = () => {
  return http.get<Array<IProductResponse>>(`/api/v1/products/count`);
};

const get = (productId: number) => {
  return http.get<IProductResponse>(`/api/v1/products/${productId}`);
};

const getbyASIN = (asinCode: number) => {
  const obj = {
    asin: asinCode,
  };
  return http.put<IProductResponse>(`/api/v1/products`, obj);
};

const update = (productId: any, data: IProductRequest) => {
  return http.patch<any>(`/api/v1/products/${productId}`, data);
};

const create = (data: IProductRequest) => {
  return http.post<any>(`/api/v1/products`, data);
};

const remove = (productId: any) => {
  return http.delete<any>(`/api/v1/products/${productId}`);
};

const ProductService = {
  getAll,
  getAllCount,
  get,
  getbyASIN,
  create,
  update,
  remove,
};

export default ProductService;
