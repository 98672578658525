import http from "../http-common";
import {
  IPaginatedFbaOrdersResponse,
  IFbaOrderResponse,
  IFbaOrderRequest,
  IFbaOrderUpdateRequest,
} from "../types/FbaOrder";

const getFbaOrders = (filterCriteria: IFbaOrderRequest) => {
  return http.get<IPaginatedFbaOrdersResponse>(`/api/v2/fba-orders${`${filterCriteria}`}`);
};

const getFbaOrderById = (id: number) => {
  return http.get<IFbaOrderResponse>(`/api/v2/fba-orders/${id}`);
};

const updateFbaOrderStatus = (id: number, requestBody: IFbaOrderUpdateRequest) => {
  return http.put<IFbaOrderResponse>(`/api/v2/fba-orders/${id}/status`, requestBody);
};

const deleteFbaOrderById = (id: number) => {
  return http.delete<IFbaOrderResponse>(`/api/v2/fba-orders/${id}`);
};

const FbaOrderService = {
  getFbaOrders,
  getFbaOrderById,
  updateFbaOrderStatus,
  deleteFbaOrderById,
};

export default FbaOrderService;