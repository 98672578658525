// App.tsx
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import trTR from "antd/lib/locale/tr_TR";
import { useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import RegisterPage from "./views/RegisterPage";
//Style
import "./assets/style/main.scss";
//Views
import LoginPage from './views/LoginPage';
import MainLayout from './views/Layout';
import FBACreate1 from './views/FBACreate';
import NewSupport from './views/NewSupport';
import SupportView from './views/SupportView';
import './App.css';
import EmailVerificationCallback from './views/EmailVerificationCallback';
import EmailVerification from './views/Auth/EmailVerification';
import NewPassword from './views/Auth/NewPassword';
import ForgotPassword from './views/Auth/ForgotPassword';
import VerifyAgain from './views/Auth/VerifyAgain';
import Services from './views/Services';
import UserList from './views/UserList';
import Warehouses from './views/Warehouses';
import WarehouseManagement from './views/WarehouseManagement';
import VerifyExternalToken from './views/VerifyExternalToken';
import FBMCreate from './views/FBMCreate';
import Roles from './views/Roles';
import DashboardV2 from './views/Dashboard/DashboardV2';
import TicketsV2 from './views/V2/TicketsV2';
import ProfileV2 from './views/V2/ProfileV2';
import WalletV2 from './views/V2/WalletV2';
import FBAPageV2 from './views/V2/FBAPageV2';
import FBMPageV2 from './views/V2/FBMPageV2';
import InventoryV2 from './views/V2/InventoryV2';
import OrderDetailV2 from './views/V2/OrderDetailV2';
import CustomersV2 from './views/V2/CustomersV2';
import InventoryNewV2 from './views/V2/InventoryNewV2';
import ClaimOrderDetail from './views/V2/ClaimOrderDetail';
import FBADetailPrint from './components/V2/FBAV2/FBADetailPrint';
import FBMDetailPrint from './components/V2/FBMV2/FBMDetailPrint';
import StorageDetailPrint from './components/V2/InventoryShipmentV2/StorageDetailPrint';
import Education from './views/Education';
import StoragePageV2 from './views/V2/StoragePageV2';
import WarehousesMe from './views/WarehousesMe';
import OrderDetail from "./views/V2/OrderDetail";

const NotFound = () => <div>404 Page Not Found</div>;

const App: React.FC = () => {
  const savedLanguage = localStorage.getItem("appLanguage");
  const initialLocale = savedLanguage === "en" ? enUS : trTR; // Varsayılan dil Türkçe
  const [locale, setLocale] = useState(initialLocale);

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={locale}>
        <Router>
          <Routes>
            <Route
              key="Main"
              element={
                <MainLayout>
                  <Outlet />
                </MainLayout>
              }
            >
              <Route path="/" element={<DashboardV2 />} />
              <Route path="/fba" element={<FBAPageV2 />} />
              <Route path="/fbm" element={<FBMPageV2 />} />
              <Route path="/storage" element={<StoragePageV2 />} />
              <Route path="/wallet" element={<WalletV2 />} />
              <Route path="/customers" element={<CustomersV2 />} />
              <Route path="/settings/users" element={<UserList />} />
              <Route path="/profile" element={<ProfileV2 />} />
              <Route path="/fba/new" element={<FBACreate1 />} />
              <Route path="/fbm/new" element={<FBMCreate />} />
              <Route path="/inventory" element={<InventoryV2 />} />
              <Route
                path={`/fba-order/detail/:id`}
                element={<OrderDetail />}
              />
              <Route
                path={`/fbm-order/detail/:id`}
                element={<OrderDetail />}
              />
              <Route
                path={`/storage-order/detail/:id`}
                element={<OrderDetail />}
              />
              <Route
                path={`/claim/detail/:id`}
                element={<ClaimOrderDetail />}
              />
              <Route
                path={`/order/detail/:claimOrderId`}
                element={<ClaimOrderDetail />}
              />
              <Route
                path={`/order/detail/:id/new`}
                element={<OrderDetailV2 />}
              />
              <Route path={`/storage/new`} element={<InventoryNewV2 />} />
              <Route path={`/support/new`} element={<NewSupport />} />
              <Route path={`/support/detail`} element={<SupportView />} />
              <Route path={`/settings/services`} element={<Services />} />
              <Route path={`/tickets`} element={<TicketsV2 />} />
              <Route path={`/settings/warehouses`} element={<Warehouses />} />
              <Route
                path={`/settings/warehouse-management`}
                element={<WarehouseManagement />}
              />
              <Route path={`/settings/roles`} element={<Roles />} />
              <Route path={`/education`} element={<Education />} />
              <Route path={`/fba-warehouses`} element={<WarehousesMe />} />
            </Route>
            <Route path={`/fba/print/:id`} element={<FBADetailPrint />} />
            <Route path={`/fbm/print/:id`} element={<FBMDetailPrint />} />
            <Route
              path={`/storage/print/:id`}
              element={<StorageDetailPrint />}
            />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/verify-external-token"
              element={<VerifyExternalToken />}
            />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="*" element={<NotFound />} />
            <Route
              path={`/email-verification-callback`}
              element={<EmailVerificationCallback />}
            />
            <Route path="/verify" element={<EmailVerification />} />
            <Route path="/reset-password" element={<NewPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify-email" element={<VerifyAgain />} />
          </Routes>
        </Router>
      </ConfigProvider>
    </I18nextProvider>
  );
};

export default App;
