import http from "../http-common";

const create = (data: any) => {
  return http.post<any>(`/api/v1/orders`, data);
};

const statusUpdate = (id: any, data: any) => {
  return http.put<any>(`/api/v1/orders/${id}/status`, data);
};

const getAll = (filter = "") => {
  let role = localStorage.getItem("userRole");
  if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
    return http.get<Array<any>>(`/api/v1/orders${filter}`);
  }
  return http.get<Array<any>>(`/api/v1/users/me/orders${filter}`);
};

const getAllWithPagination  = (filterCriteria: string = ''  ) => {
  let role = localStorage.getItem("userRole");
  if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
    return http.get<Array<any>>(`/api/v1/orders${filterCriteria}`);
  }
  return http.get<Array<any>>(`/api/v1/users/me/orders${filterCriteria}`);
}

const getFnskuLabel = (orderItemId: any) => {
  return http.get<any>(`/api/v1/order-items/${orderItemId}/fnsku/download`, {
    responseType: "arraybuffer",
  });
};

const getFBALabel = (orderItemId: any) => {
  return http.get<any>(`/api/v1/orders/${orderItemId}/fba/download`, {
    responseType: "arraybuffer",
  });
};

const getShippingLabel = (orderItemId: any) => {
  return http.get<any>(`/api/v1/orders/${orderItemId}/shipping-label/download`, {
    responseType: "arraybuffer",
  });
};

const get = (id: any) => {
  return http.get<any>(`/api/v1/orders/${id}`);
};

const getClaimOrderId = (claimOrderId: any) => {
  return http.get<any>(`/api/v1/orders/claim-order/${claimOrderId}`);
};

const getAllCount = (filter = "") => {
  let role = localStorage.getItem("userRole");
  if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
    return http.get<Array<any>>(`/api/v1/orders/count${filter}`);
  }
  return http.get<Array<any>>(`/api/v1/users/me/orders/count${filter}`);
};

const orderPay = (id: any) => {
  return http.post<any>(`/api/v1/orders/${id}/payments`);
};

const updateTrackingCode = (id: any, trackingCode: any) => {
  const postObj = {
    trackingCode: trackingCode,
  };

  return http.put<any>(`/api/v1/order-items/${id}/tracking-code`, postObj);
};

const OrderService = {
  getAll,
  getAllWithPagination,
  orderPay,
  statusUpdate,
  get,
  create,
  getAllCount,
  getClaimOrderId,
  updateTrackingCode,
  getFnskuLabel,
  getFBALabel,
  getShippingLabel,
};

export default OrderService;
