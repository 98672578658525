import { useEffect, useState } from "react";
import { Card, Dropdown, Image, Menu, Row, Table, Tag, Tooltip, Pagination, Button, Popconfirm, Col, Tabs } from "antd";
import type { PaginationProps, TabsProps } from "antd";
import { ColumnProps } from "antd/es/table";
import { EllipsisOutlined } from "@ant-design/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { formatDateTime, openNotification } from "../../utils";
import { CopyClipboard } from "../FBACreate/CopyClipboard";
import FbaOrderService from "../../services/FbaOrderService";
import FbmOrderService from "../../services/FbmOrderService";
import { IFbaOrderResponse } from "../../types/FbaOrder";
import { IFbmOrderResponse } from "../../types/FbmOrder";
import ClaimOrderService from "../../services/ClaimOrderService";
import { OrderStatus } from "../../enums/OrderStatus";

const ShipmentList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [orderList, setOrderList] = useState<IFbaOrderResponse[] | IFbmOrderResponse[]>([]);
  // eslint-disable-next-line
  const [orderStatus, setOrderStatus] = useState<OrderStatus>("WAITING" as OrderStatus);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  const currentRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("id");
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText = () => {
    if (currentRole === "CUSTOMER") {
      return `?page=${lastCurrentPage}&size=${lastPageSize}&statuses=${orderStatus}&userId=${userId}`;
    } else {
      return `?page=${lastCurrentPage}&size=${lastPageSize}&statuses=${orderStatus}`;
    }
  };
  const [filterText, setFilterText] = useState<any>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);

  const items: TabsProps["items"] = [
    { key: "1", label: "FBA" },
    { key: "2", label: "FBM" },
    ...(currentRole === "CUSTOMER" ? [{ key: "3", label: "STORAGE" }] : []),
  ];

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(/(statuses=)[^&]*/, `$1${orderStatus}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [activeKey, currentPage, pageSize, orderStatus, sortField, sortOrder]);

  const getOrderList = () => {
    setLoading(true);
    if (activeKey === "1") {
      FbaOrderService.getFbaOrders(filterText)
        .then((response) => {
          const orderList = response.data.data.map(
            (order) => new IFbaOrderResponse(order)
          );
          setOrderList(orderList);
          setTotalOrders(response.data.totalElements);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (activeKey === "2") {
      FbmOrderService.getFbmOrders(filterText)
        .then((response) => {
          const orderList = response.data.data.map(
            (order) => new IFbmOrderResponse(order)
          );
          setOrderList(orderList);
          setTotalOrders(response.data.totalElements);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (activeKey === "3") {
      // StorageOrderService.getStorageOrders()
      //   .then((response) => {
      //     const orderList = response.data.data.map((order) => new IStorageOrderResponse(order));
      //     setOrderList(orderList);
      //     setTotalOrders(response.data.totalElements);
      //   })
      //   .catch((e) => console.log(e))
      //   .finally(() => setLoading(false));
    }
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction = order === "ascend" ? "ASC" : order === "descend" ? "DESC" : undefined;
    const sortParamRegex = /(&sort=[^&]*)|(&direction=[^&]*)/g;
    let newFilterText = filterText.replace(sortParamRegex, "");
    if (field && direction) {
      newFilterText += `&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };
  
  useEffect(() => {
    getOrderList();
    // eslint-disable-next-line
  }, [activeKey, filterText, sortField, sortOrder]);

  const handleCancel = (id: number) => {
    setLoading(true);
    ClaimOrderService.cancel(id)
      .then(() => {})
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const columns: ColumnProps<IFbaOrderResponse | IFbmOrderResponse>[] = [
    {
      title: "Order Code",
      dataIndex: "orderCode",
      key: "orderCode",
      width: 150,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <span>{text}</span>
        </NavLink>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      sorter: true,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <span>{formatDateTime(text)}</span>
        </NavLink>
      ),
    },
    {
      title: "Customer",
      dataIndex: "user",
      key: "user",
      ellipsis: { showTitle: false },
      width: 150,
      sorter: true,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <Tooltip
            placement="topLeft"
            title={`${record?.user?.firstName} ${record?.user?.lastName} #${record?.user?.id}`}
          >
            <p style={{ margin: 0 }}>
              {record?.user?.firstName} {record?.user?.lastName}
            </p>
            <b>#{record?.user?.id}</b>
          </Tooltip>
        </NavLink>
      ),
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: 220,
      render: (text, record) => (
        <Tooltip
          title={record?.items
            ?.map((item) => item?.productDetail?.title)
            .join(", ")}
        >
          <span>
            {record?.items?.map((item, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <NavLink
                  to={`https://www.amazon.com/dp/${item?.productDetail?.asin}`}
                  target="_blank"
                  style={{ marginRight: "10px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `https://www.amazon.com/dp/${item?.productDetail?.asin}`,
                      "_blank"
                    );
                  }}
                >
                  <Image
                    style={{
                      borderRadius: "50%",
                      border: "3px solid rgba(51,62,72,0.5)",
                    }}
                    height={"40px"}
                    width={"40px"}
                    preview={false}
                    src={`https://api.oneamz.com/product-photo/${
                      item?.productDetail?.asin
                    }?Authorization=${localStorage.getItem(
                      "externalAccessToken"
                    )}`}
                  />
                </NavLink>
                <CopyClipboard text={item?.productDetail?.asin} />
              </div>
            ))}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Received/Total QTY",
      dataIndex: "items",
      key: "qty",
      align: "center",
      width: 150,
      // sorter: (a, b) => {
      //   const getReceivedQty = (items: any[]) =>
      //     items.reduce((acc, item) => acc + (item?.received || 0), 0);  
      //   const aReceivedQty = getReceivedQty(a.items);
      //   const bReceivedQty = getReceivedQty(b.items);  
      //   return aReceivedQty - bReceivedQty;
      // },
      render: (text, record) => {
        const totalQty = record?.items?.reduce(
          (a, b) => a + (b?.quantity || 0),
          0
        );
        const receivedQty = record?.items?.reduce(
          (a, b) => a + (0 || 0),
          0
        );

        return (
          <NavLink to={record?.detailUrl} style={{ color: "black" }}>
            <span>
              {receivedQty} / {totalQty} PCS
            </span>
          </NavLink>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <Tag
            color={"orange" || "inherit"}
            style={{
              fontWeight: "500",
              whiteSpace: "wrap",
              textAlign: "center",
            }}
          >
            {record?.status}
          </Tag>
        </NavLink>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (text, record) =>
        currentRole === "CUSTOMER" ||
        record.status === "SHIPPED" ||
        record.status === "COMPLETED" ||
        record.status === "CANCELLED" ? (
          <Button
            type="link"
            style={{ color: "black", border: "1px solid black" }}
            onClick={() => {
              navigate(`${record?.detailUrl}`);
            }}
          >
            View
          </Button>
        ) : (
          <Dropdown
            placement="topRight"
            overlay={
              <Menu>
                <Menu.Item>
                  <NavLink to={record?.detailUrl}>View Details</NavLink>
                </Menu.Item>
                <Popconfirm
                  title="Are you sure you want to delete this order?"
                  onConfirm={() => handleCancel(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Menu.Item>Delete</Menu.Item>
                </Popconfirm>
              </Menu>
            }
          >
            <EllipsisOutlined
              rotate={90}
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          </Dropdown>
        ),
    },
  ];

  const renderCard = () => (
    <Card
      className="antd-card-body-p-0 bg-transparent w-full"
      loading={loading}
    >
      <Table
        loading={loading}
        rowKey={"id"}
        columns={columns}
        dataSource={orderList}
        bordered
        scroll={{ x: 1000 }}
        pagination={false}
        onChange={handleTableChange}
      />
      <Row style={{ marginTop: "30px" }} justify={"center"}>
        <Pagination
          showSizeChanger={true}
          total={totalOrders}
          defaultPageSize={pageSize}
          defaultCurrent={0}
          pageSize={pageSize}
          current={currentPage + 1}
          showQuickJumper
          onChange={paginationOnChange}
        />
      </Row>
    </Card>
  );

  return (
    <>
      <Card className="bg-cloud border-radius-md">
        <Row justify={"space-between"}>
          <Col xs={24} md={12}>
            <h3 className="h-3 m-0">Shipment List</h3>
          </Col>
          <Col xs={24} md={12}>
            <Tabs
              defaultActiveKey="1"
              className="w-full antd-tab-right antd-tab-text"
              items={items}
              onChange={onChange}
            />
          </Col>
        </Row>
        <Row>{renderCard()}</Row>
      </Card>
    </>
  );
};

export default ShipmentList;
