import { Button, Card, Col, Divider, Popconfirm, Row } from "antd";
import React from "react";
import StorageItemCardManager from "./StorageItemCardManager";
import { openNotification } from "../../../utils";
import OrderService from "../../../services/OrderService";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../../assets/icons/User.svg";
import { debounce } from "lodash";
import { IFbaOrderResponse } from "../../../types/FbaOrder";
import { IFbmOrderResponse } from "../../../types/FbmOrder";

interface OrderDetailCardProps {
  order: IFbaOrderResponse | IFbmOrderResponse;
  setQty: (id: any, qty: any) => void;
}

const StorageDetailManager: React.FC<OrderDetailCardProps> = ({
  order,
  setQty,
}) => {
  const location = useLocation();
  const readOnly = !location.pathname.includes("/storage-order/detail") ? true : false;
  const navigate = useNavigate();

  const confirmOrder = debounce(() => {
    let orderItems: any = [];
    let errorCount = 0;
    order?.items?.map((orderItem: any) => {
      if (orderItem?.quantity > 0) {
        let itemServices: any[] = [];

        // orderItem?.services?.map((servicesItem: any) => {
        //   let serviceItemObj = {
        //     generalServiceId: servicesItem?.generalService?.id,
        //     qty: orderItem?.qty,
        //   };

        //   itemServices.push(serviceItemObj);
        // });

        // orderItem?.binId = 1;

        let itemObj = {
          orderItemId: orderItem?.id,
          qty: orderItem?.quantity,
          // binId: orderItem?.binId,
          claimOrderItemGeneralServices: itemServices,
        };
        orderItems.push(itemObj);
      }
    });

    if (errorCount > 0) {
      openNotification(
        "error",
        "Error",
        "Select the shelf where the product will be stored"
      );
      return false;
    }
    if (orderItems.length === 0) {
      openNotification("error", "Error", "Product quantities cannot be zero");
      return false;
    }

    let orderGeneralServices: any = [];

    // order.generalServices?.map((generalServiceItem: any) => {
    //   let orderGeneralService = {
    //     generalServiceId: generalServiceItem.generalService.id,
    //     qty: generalServiceItem.qty,
    //   };

    //   orderGeneralServices.push(orderGeneralService);
    // });

    let postObj = {
      orderId: order.id,
      orderItems: orderItems,
      employeeNote: "",
      oneamzToken: localStorage.getItem("externalAccessToken"),
      orderGeneralServices: orderGeneralServices,
    };
    OrderService.create(postObj)
      .then((response: any) => {
        openNotification("success", "Success", "Shipment confirmed");
        setTimeout(() => {
          window.location.replace(`/order/detail/${order.id}`);
        }, 1000);
      })
      .catch((e: Error) => {
        openNotification("error", "Error", "Failed to confirm shipment");
      });
  }, 300);

  return (
    <Row justify={"space-between"}>
      <Col xl={8} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">User Information</h3>
          </Col>
        </Row>
        <Card className="bg-slate-blue border-radius-md">
          <Row>
            <Col span={4}>
              <UserIcon />
            </Col>
            <Col span={20}>
              <h2 className="font-color-pure-white mt-0 mb-0">
                <b>
                  {order?.user?.firstName} {order?.user?.lastName}
                </b>
              </h2>
              <h2 className="font-color-pure-white mt-0">
                <b>#{order?.user?.id} </b>
              </h2>
              <p className="text font-color-pure-white mt-24 mb-0">
                {order?.user?.phoneNumber}
              </p>
              <p className="text font-color-pure-white mt-0 mb-0">
                {order?.user?.email}
              </p>
            </Col>
          </Row>
        </Card>
        <Card className="bg-cloud border-radius-md mt-24">
          <Row>
            <Col span={24}>
              <h3 className="text font-color-onyx mt-0">
                <b>Note</b>
              </h3>
              {/* <p className="text font-color-onyx m-0">{order?.note}</p> */}
            </Col>
          </Row>
        </Card>
      </Col>
      <Col>
        <Divider type="vertical" className="vertical-divider" />
      </Col>
      <Col xl={15} xs={24}>
        {order?.items.map((item: any, index: any) => (
          <StorageItemCardManager
            key={index}
            order={order}
            setQty={setQty}
            orderItem={item}
          />
        ))}
      </Col>
      <Col xl={24} xs={24}>
        {!readOnly && (
          <Row justify={"end"} className="mt-24">
            <Popconfirm
              title="Do you confirm product entry into the warehouse?"
              onConfirm={confirmOrder}
            >
              <Button className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-lg mt-24">
                Confirm
              </Button>
            </Popconfirm>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default StorageDetailManager;
