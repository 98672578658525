import { Card, Col, Row, Segmented } from "antd";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DateRange from "../Common/DateRange";
import { getDateRangeDefault } from "../../utils";
import ClaimOrderService from "../../services/ClaimOrderService";

// Chart 2
const Graph = () => {
  const [dateRange, setDateRange] = useState<any[]>(getDateRangeDefault);
  const [data, setData] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [label, setLabel] = useState<any>("FBA");
  const [orderType, setOrderType] = useState<any>("FBA_SHIPMENT");

  const getReport = (filter = "") => {
    ClaimOrderService.getReport(filter)
      .then((response: any) => {
        let data = response.data;
        let chartData = data.map((item: any) => item.count);
        let dates = data.map((item: any) => item.date);
        setData(chartData);
        setCategories(dates);
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    getReport(
      `?startDate=${dateRange[0]}&endDate=${dateRange[1]}&orderType=${orderType}`
    );
  }, [orderType, dateRange]);

  const series: ApexAxisChartSeries = [
    {
      name: label + " operations",
      data: data,
      color: "#FFCB2B",
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: "amz-example",
      toolbar: {
        show: false, // This will hide the toolbar
      },
      background: "transparent",
    },
    xaxis: {
      categories: categories,
      type: "datetime",
    },
    stroke: {
      curve: "smooth", // Ensuring that curve is one of the allowed types
    },
    yaxis: {
      show: false, // This will hide the y-axis
    },
    grid: {
      borderColor: "#555",
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "";
        },
      },
    },
  };

  return (
    <>
      <Card className="bg-cloud h-full border-radius-md">
        <Row align={"middle"} justify={"space-between"}>
          <Col xxl={12}>
            <h3 className="h-3 font-color-slate-blue m-0">Process Graph</h3>
          </Col>
          <Col xxl={12} xs={24}>
            <DateRange
              onChangeDateRange={(dateRange) => setDateRange(dateRange)}
            ></DateRange>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={300}
            />
          </Col>
        </Row>
        <Row align={"middle"} gutter={16}>
          <Segmented
            className="btn-text"
            options={["FBA", "FBM", "STORAGE"]}
            onChange={(value) => {
              if (value === "FBA") {
                setLabel("FBA");
                setOrderType("FBA_SHIPMENT");
              } else if (value === "FBM") {
                setLabel("FBM");
                setOrderType("FBM_SHIPMENT");
              } else if (value === "STORAGE") {
                setLabel("STORAGE");
                setOrderType("STORAGE");
              }
            }}
          />
        </Row>
      </Card>
    </>
  );
};

export default Graph;
