import React from "react";
import { Card, Col, Row, Empty } from "antd";
import PackageList from "./PackageList";
import LabelUpload from "./LabelUpload";
import AmazonProductImage from "../Common/AmazonProductImage";
import AmazonProductInfo from "../Common/AmazonProductInfo";

interface FBACreateStepProps {
  setTrackingCode: (id: any, code: any) => void;
  setFNSKULabel: (id: any, file: any) => void;
  setPackageCount: (id: any) => void;
  setFBALabel: (file: any) => void;
  productList: [];
  totalQty: 0;
  totalWeight: 0;
  totalDeci: 0;
  fbaLabel: null;
  orderServices: [];
}

const FBACreateStep3: React.FC<FBACreateStepProps> = ({
  setFNSKULabel,
  setPackageCount,
  setFBALabel,
  productList,
  fbaLabel,
  orderServices,
  totalWeight,
}) => {
  const FNSKULabelRender = (item: any) => {
    const response = productList.map((orderItem: any, index: number) => {
      if (orderItem.id === item.id) {
        let services = orderItem.services;
        for (let i = 0; i < services.length; i++) {
          const service = services[i];
          if (service.id === 1 && service.qty > 0) {
            return (
              <LabelUpload
                fbaLabel={null}
                type="fnsku"
                setLabel={(file) => setFNSKULabel(item.id, file)}
                product={item}
              ></LabelUpload>
            );
          }
        }
      }
    });
    return response;
  };

  return (
    <>
      <h3 className="h-3 mt-0 font-color-slate-blue mt-24">
        Product Information
      </h3>
      <Card className="bg-cloud border-radius-md mt-24">
        {productList.map((item: any, key) => (
          <Card key={key} className="border-radius-md w-full mb-24">
            <Row align={"top"} justify={"space-between"}>
              <Col span={9}>
                <Row gutter={[24, 24]}>
                  <Col>
                    <AmazonProductImage asin={item.asin}></AmazonProductImage>
                  </Col>
                  <Col span={16}>
                    <AmazonProductInfo
                      asin={item.asin}
                      title={item.title}
                    ></AmazonProductInfo>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <p className="text-sm fs-12 m-0">
                      <b>DIMENSIONS</b>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0">
                      {item.length} x {item.width} x {item.height} in
                      <br />
                      {item.weight} lb
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={2} style={{ textAlign: "left" }}>
                <Row justify={"center"}>
                  <Col>
                    <p className="text-sm fs-12 m-0">
                      <b>QUANTITY</b>
                    </p>
                  </Col>
                </Row>
                <Row justify={"center"}>
                  <Col>
                    <p className="mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0">
                      {item.qty}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>{FNSKULabelRender(item)}</Col>
            </Row>
          </Card>
        ))}
        {productList.length === 0 ? (
          <Empty
            description="Add product"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <></>
        )}
      </Card>
      <Row className="mt-24">
        <h3 className="h-3 mt-0 font-color-slate-blue">Package Information</h3>
        <Col xs={24} sm={24} md={24}>
          <PackageList
            fbaLabel={fbaLabel}
            orderServices={orderServices}
            orderItemList={productList}
            totalWeight={totalWeight}
            changeBoxCount={setPackageCount}
            setFBALabel={setFBALabel}
          ></PackageList>
        </Col>
      </Row>
    </>
  );
};

export default FBACreateStep3;
