import React, { useEffect, useState } from "react";
import FbaImg from "../../../assets/V2/PrintV2/fba-order-yellow.png";
import { Card, Col, Row, Image, Avatar, Checkbox, Divider } from "antd";
import { ReactComponent as CustomerIcon } from "../../../assets/V2/LayoutV2/customer-active.svg";
import { ReactComponent as WarehouseIcon } from "../../../assets/V2/LayoutV2/inventory-active.svg";
import { ReactComponent as ShipmentIcon } from "../../../assets/V2/AdminDashboardV2/waiting-icon.svg";
import { useParams } from "react-router-dom";
import ClaimOrderService from "../../../services/ClaimOrderService";
import AmazonProductImage from "../../Common/AmazonProductImage";
import AmazonProductInfo from "../../Common/AmazonProductInfo";

const StorageDetailPrint = () => {
  const [order, setOrder] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const [orderItemList, setOrderItemList] = useState<any>();
  let { id } = useParams();

  useEffect(() => {
    getOrderDetail();
  }, []);

  const getOrderDetail = () => {
    setLoading(true);
    ClaimOrderService.get(id)
      .then((response: any) => {
        let data = response.data;
        let claimOrderObj = data.claimOrderItems.map((updatedItem: any) => {
          updatedItem.approvalQty = updatedItem.qty;
          return updatedItem;
        });

        data.claimOrderItems = claimOrderObj;

        setOrder(data);
        setOrderItemList(data.claimOrderItems);
        setLoading(false);
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log(e);
      });
  };
  return (
    <>
      <Row className="m-24">
        <Col xxl={18} xs={24}>
          <Card className="border-radius-md">
            <Row justify={"space-between"}>
              <Col xl={8}>
                <Row gutter={24}>
                  <Col className="pt-8">
                    <Image src={FbaImg}></Image>
                  </Col>
                  <Col>
                    <h2 className="h-2 m-0">ONESHIP-{order?.user?.id}</h2>
                    <h2 className="h-2 m-0">STORAGE-{order?.id}</h2>
                  </Col>
                </Row>
              </Col>
              <Col xl={6}>
                <Row gutter={[24, 24]} justify={"end"}>
                  <Col>
                    <CustomerIcon className="wh-25 mt-8" />
                  </Col>
                  <Col>
                    <h3 className="h-3 m-0">User Information</h3>
                    <p className="m-0 fs-12">
                      <b>
                        {order?.user?.firstName} {order?.user?.lastName}
                      </b>
                    </p>
                    <p className="mt-0 fs-12">
                      <b>#{order?.user?.id}</b>
                    </p>
                    <p className="m-0 fs-14">{order?.user?.phoneNumber}</p>
                    <p className="m-0 fs-14">{order?.user?.email}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <h3 className="h-3 mt-64">Incoming Products</h3>
              {orderItemList?.map((item: any) => (
                <Col span={24}>
                  <Card className="border-slate-blue-02 border-radius-md">
                    <Row align={"middle"} justify={"space-between"}>
                      <Col xs={16}>
                        <Row>
                          <Col className="mr-24">
                            <AmazonProductImage
                              asin={item.productDetail.asin}
                            ></AmazonProductImage>
                          </Col>
                          <Col span={16}>
                            <AmazonProductInfo
                              asin={item.productDetail.asin}
                              title={item.productDetail.title}
                            ></AmazonProductInfo>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={3}>
                        <p className="btn-text font-color-slate-blue-05 m-0">
                          Quantity
                        </p>
                        <h3 className="h-3 font-color-slate-blue m-0">
                          {item?.qty}
                        </h3>
                      </Col>
                      <Col xs={3}>
                        <p className="btn-text font-color-slate-blue-05 m-0">
                          Box
                        </p>
                        <h3 className="h-3 font-color-slate-blue m-0">-</h3>
                      </Col>
                      <Col xs={2}>
                        <div className="wh-32 border-slate-blue-02 border-radius-8"></div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row gutter={24} justify={"end"} className="mt-48">
              <Col>
                <p className="text m-0">
                  <b>Shipment Manager</b>
                </p>
                <p className="text m-0">
                  <b>Name Surname</b>
                </p>
                <p className="mt-48 mb-0 text fs-14">09/02/2024</p>
              </Col>
              <Col>
                <Card className="width-200 h-full border-slate-blue-05"></Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default StorageDetailPrint;
