// export enum OrderStatus {
//   // claim order status
//   DRAFT = "DRAFT",
//   APPROVED = "APPROVED",
//   CANCELLED = "CANCELLED",
//   // order status
//   PAYMENT_PENDING = "PAYMENT_PENDING",
//   WAITING = "WAITING",
//   WAREHOUSE = "ONEAMZ WAREHOUSE",
//   COMPLETED = "COMPLETED",
//   SHIPPED = "SHIPPED",
// }

export enum OrderStatus {
  WAITING = "WAITING",
  IN_WAREHOUSE = "IN_WAREHOUSE",
  SHIPPED = "SHIPPED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export const OrderStatusDetails: {
  [key in OrderStatus]: { label: string; color: string };
} = {
  [OrderStatus.WAITING]: { label: "Waiting", color: "orange" },
  [OrderStatus.IN_WAREHOUSE]: { label: "In Warehouse", color: "purple" },
  [OrderStatus.CANCELLED]: { label: "Cancelled", color: "red" },
  [OrderStatus.COMPLETED]: { label: "Completed", color: "blue" },
  [OrderStatus.SHIPPED]: { label: "Shipped", color: "green" },
};
