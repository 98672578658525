import { Col, Row } from "antd";
import React from "react";
import { ReactComponent as AnnouncementIcon } from "../../../assets/V2/LayoutV2/announcements.svg";

const Announcement = () => {
  return (
    <>
      <Row className="mt-8 mb-12" align={"middle"} justify={"space-between"}>
        <Col>
          <p className="pretitle font-color-slate-blue-05 mb-0">
            ANNOUNCEMENTS
          </p>
        </Col>
        <Col>
          <AnnouncementIcon className="mt-16" />
        </Col>
      </Row>
      <Row className="mt-24 mb-12" align={"middle"} justify={"center"}>
        <Col>
          <p className="text-small font-color-slate-blue-05 mt-0">
            No New Announcements
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Announcement;
