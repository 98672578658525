import http from "../http-common";
import { IShelfRequest, IShelfResponse } from "../types/Shelf";

const getAll = () => {
  return http.get<Array<IShelfResponse>>(`/api/v1/shelves`);
};

const get = (shelfId: number) => {
  return http.get<IShelfResponse>(`/api/v1/shelves/${shelfId}`);
};

const update = (shelfId: any, data: IShelfRequest) => {
  return http.put<any>(`/api/v1/shelves/${shelfId}`, data);
};

const create = (data: IShelfRequest) => {
  return http.post<any>(`/api/v1/shelves`, data);
};

const remove = (shelfId: any) => {
  return http.delete<any>(`/api/v1/shelves/${shelfId}`);
};

const ShelfService = {
  getAll,
  get,
  update,
  create,
  remove,
};

export default ShelfService;
