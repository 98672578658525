import http from "../http-common";

const getAll = () => {
  let role = localStorage.getItem("userRole");
  if (role == "CUSTOMER") {
    return http.get<Array<any>>(`/api/v1/users/me/tickets`);
  }
  return http.get<Array<any>>(`/api/v1/tickets`);
};

const create = (data: any) => {
  return http.post<any>(`/api/v1/users/me/tickets`, data);
};

const createComments = (data: any) => {
  return http.post<any>(`/api/v1/users/me/ticket-comments`, data);
};

const get = (id: any) => {
  return http.get<any>(`/api/v1/tickets/${id}`);
};

const update = (id: any, data: any) => {
  return http.patch<any>(`/api/v1/tickets/${id}`, data);
};

const TicketService = {
  create,
  getAll,
  get,
  update,
  createComments,
};

export default TicketService;
